
import { IDocumentBE, ICaseBE } from "@datalex-software-as/datalex-client";

export interface IFile {
  fileData: File,
  base64: Blob
}

export default class FileManager {
  files!: FileList;
  base64: any[] = [];


  constructor() { }

  private readFile(file: File) {
    return new Promise((resolve, reject) => {
      let fr = new FileReader();

      fr.onload = () => {
        resolve({
          base64: fr.result,
          fileData: file
        });
      };

      fr.onerror = () => {
        reject(fr);
      };

      fr.readAsDataURL(file);
    });
  }

  public newFiles(files: FileList) {
    let readers = [];
    if (!files) return;
    if (!files.length) return;
    for (let i = 0; i < files.length; i++) {
      readers.push(this.readFile(files[i]));
    }

    Promise.all(readers).then((values) => {
      this.base64 = values;
    });
  }


  public async uploadFilesNoDialog(files: FileList) {
    let readers = [];
    if (!files.length) return;
    for (let i = 0; i < files.length; i++) {
      readers.push(this.readFile(files[i]));
    }
    return await Promise.all(readers);
  }

  public createDocumentWithDefaultValues(document: IDocumentBE, file: File, createdBy: string, contactId: string | null = null, caseId: string | null = null) {



    let newDocument = document;
    newDocument.AuthorId = createdBy;

    newDocument.CaseId = caseId;
    newDocument.ContactId = contactId;
    newDocument.AccountsId = null;
    newDocument.VoucherSeriesId = null;
    newDocument.VoucherNumber = null;
    newDocument.FinancialYear = null;
    newDocument.BankruptcyClaimId = null;
    newDocument.BankruptcyEstateId = null;

    newDocument.Date = new Date(file.lastModified).toISOString();
    newDocument.DateChanged = new Date(file.lastModified).toISOString();

    newDocument.DocumentTypeId = "21321311-0000-0000-0000-000000000080";
    newDocument.IsOut = false;

    newDocument.OriginCode = "WEB";

    newDocument.Title = this.setDocumentTitle(file.name);


    newDocument.File!.FileData = null;
    newDocument.File!.Filename = this.sanitizeFilename(file.name);
    let fileExstension = file.name.split(".");
    newDocument.File!.Extension = `.${fileExstension[fileExstension.length - 1]}`;
    newDocument.File!.FileSize = file.size;
    newDocument.File!.ChangedDate = new Date(0).toISOString();

    return newDocument;
  }

  private sanitizeFilename(filename: string) {
    const unsafeCharacters = new Set(['<', '>', '/', '\\', '"', '\'', '&', '?']);

    let _temp = '';
    for (const char of filename) {
      _temp += unsafeCharacters.has(char) ? '_' : char;
    }

    return _temp.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '_')
      .trim();
  }


  private setDocumentTitle(filename: string) {
    return filename.split('.').slice(0, -1).join('.');
  }


  async createDownloadLinkFromStream(stream: ReadableStream, fileName: string) {
    // 1. Convert ReadableStream to a Blob
    const reader = stream.getReader();
    const chunks: Uint8Array[] = [];
    let done = false;

    while (!done) {
      const { done: streamDone, value } = await reader.read();
      done = streamDone;
      if (value) {
        chunks.push(value);
      }
    }

    const blob = new Blob(chunks);

    // 2. Create a download link
    const blobUrl = URL.createObjectURL(blob);

    // 3. Create an anchor element and simulate a download
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName; // the name of the file to be downloaded
    document.body.appendChild(link);
    link.click();

    // 4. Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl); // release memory
  }

}

