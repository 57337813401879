<div #documentGridBoundary class="document-grid-container" (dragover)="onDragOver($event)" appDnd
  (fileDropped)="onFileDropped($event)" (zoneLeave)="onDragLeave()" (window:click)="disableContextMenu()">
  <div class="document-buttonRow">
    <div class="button-cluster ">
      <app-button-row-button [enabled]="checkDocumentFullRights()" label="Ny" icon="add" [gotDropdown]="true"
        [igxToggleAction]="newDocumentDropdown" [igxDropDownItemNavigation]="newDocumentDropdown"
        [igxToggleOutlet]="newOutlet">
      </app-button-row-button>
      <div class="overlayOutlet" igxOverlayOutlet #newOutlet="overlay-outlet"></div>
      <app-button-row-button *ngIf="windowWidth < 1410" [enabled]="selected" label="Handlinger" icon="arrow_drop_down"
        [igxToggleAction]="documentActionDropdown" [igxDropDownItemNavigation]="documentActionDropdown"
        [igxToggleOutlet]="openOutlet">
      </app-button-row-button>
      <div class="overlayOutlet" igxOverlayOutlet #openOutlet="overlay-outlet"></div>
      @if( windowWidth >= 1410) {
      @if(isOpenXMLDocument()) {
      <app-button-row-button [enabled]="selected && isLoggedInSharepoint" label="Åpne" icon="open_in_new"
        [gotDropdown]="true" [igxToggleAction]="openDocumentDropDown" [igxDropDownItemNavigation]="openDocumentDropDown"
        [igxToggleOutlet]="openDocumentOutlet" />
      }
      @else {
      <app-button-row-button [enabled]="selected" label="Åpne" icon="open_in_new" (click)="openFileManager()" />
      }

      <div class="overlayOutlet" igxOverlayOutlet #openDocumentOutlet="overlay-outlet"></div>
      <app-button-row-button [enabled]="selected && gotDocumentFullPermissions" label="Kopier og åpne"
        icon="content_copy" (click)="copyAndOpenDocument()">
      </app-button-row-button>

      <app-button-row-button [enabled]="selected && !!clickedCellRowData.InUseBy " label="Sjekk inn"
        icon="assignment_turned_in" (click)="contextCheckInDocument()" />
      <app-button-row-button [enabled]="selected" label="Detaljer" icon="info"
        (click)="contextOpenDetails(); showDetailModal = true" />
      <app-button-row-button [enabled]="selected && gotDocumentFullPermissions" label="Kategori" icon="edit"
        (click)="openDocumentCategoryDialog()" />
      <app-button-row-button [enabled]="selected" label="Last ned" icon="download"
        (click)="contextDownloadDocument()" />
      <app-button-row-button [enabled]="selected && gotDocumentFullPermissions" label="Slett" icon="delete"
        (click)="contextDeleteDocument()" />

      }

    </div>

    <div class="button-cluster">
      <app-button-row-button dlxExcelHandler [grid]="grid1" fileName="SakDokumenter" label="Excel" icon="table_view"
        [enabled]="!!filteredDatasource.length" />

      @if (!checkAccountingDocumentsRights()) {
      <label class="docRow-btn" (click)="hideDisabled()">
        <span translate="no" class="spacer material-symbols-outlined">{{disabledHidden ? 'visibility' :
          'visibility_off'}}</span>
        <!-- <span *ngIf="windowWidth > 1176" class="sharepoint-btn">{{disabledHidden ? 'Vis' : 'Skjul'}} utilgjengelig</span> -->
      </label>
      }

      @if (sys.microsoftGraphClientConfig === null) {
      <label class="docRow-btn error-button" [igxToggleAction]="sharepointDisconnectedDropdown"
        [igxDropDownItemNavigation]="sharepointDisconnectedDropdown" [igxToggleOutlet]="sharepointDisconnectedOutlet"
        [overlaySettings]="overlaySettings">
        <!--<span class="spacer material-symbols-outlined" style="color: red">link_off</span>-->
        <span class="sharepoint-btn" style="color: red">Dokumenttilkobling er ikke aktivert</span>
      </label>
      } @else {
      @if (!isLoggedInSharepoint) {
      <label class="docRow-btn" (click)="sharePointSignIn()">
        <!-- <label class="docRow-btn" (click)="initMsalAuth()"> -->
        <span translate="no" class="spacer material-symbols-outlined" style="color: orange">link_off</span>
        <span *ngIf="windowWidth > 1176" class="sharepoint-btn">Dokumenttilkobling er ikke aktiv</span>
      </label>
      } @else {
      <label class="docRow-btn">
        <span translate="no" class="spacer material-symbols-outlined" style="color: rgb(39, 189, 39)">link</span>
        <span *ngIf="windowWidth > 1176" class="sharepoint-btn">Dokumenttilkobling er aktiv</span>
      </label>
      }

      }
      <div class="overlayOutlet" igxOverlayOutlet #sharepointDisconnectedOutlet="overlay-outlet"></div>

      <label class="docRow-btn" (click)="loadDocuments()">
        <span translate="no" class="material-symbols-outlined">refresh</span>
      </label>
    </div>
  </div>

  <igx-drop-down #newDocumentDropdown>
    <div class="card-wrapper">
      <igx-card elevated>
        <igx-card-content>
          <div>
            <button class="action-menu-button" (click)="openNewDocmentFromTemplateModal(); newDocumentDropdown.close()">
              <span translate="no" class="material-symbols-outlined icon">note_add</span>
              <span>Nytt dokument fra mal</span>
            </button>
            <label for="upload" class="action-menu-button" (click)="newDocumentDropdown.close()">
              <input id="upload" type="file" multiple (change)="uploadMultipleDocuments($event, openOutlet)" hidden>
              <span translate="no" class="material-symbols-outlined icon">upload_file</span>
              <span>Last opp fil</span>
            </label>
            <button class="action-menu-button" (click)="openNoteModal(); newDocumentDropdown.close()">
              <!-- <span translate="no" class="material-symbols-outlined icon">edit_note</span> -->
              <span translate="no" class="material-symbols-outlined icon">Description</span>
              <span>Notat</span>
            </button>
          </div>
        </igx-card-content>
      </igx-card>
    </div>
  </igx-drop-down>
  <igx-drop-down #openDocumentDropDown>
    <div class="card-wrapper">
      <igx-card elevated>
        <igx-card-content>
          <div>
            <button class="action-menu-button" (click)="openFileManager(); openDocumentDropDown.close()">
              <span translate="no" class="material-symbols-outlined icon">open_in_new</span>
              <span>Åpne i nettleser</span>
            </button>
            <button class="action-menu-button" (click)="openMsoPreview(); openDocumentDropDown.close()">
              <span translate="no" class="material-symbols-outlined icon">visibility</span>
              <span>Åpne skrivebeskyttet</span>
            </button>
            @if(isWindowsDesktop){
            <button class="action-menu-button" (click)="contextOpenDocumentDesktopApp(); openDocumentDropDown.close()">
              <span translate="no" class="material-symbols-outlined icon">file_open</span>
              <span>Åpne i skrivebordsapplikasjon</span>
            </button>
            }

            <button class="action-menu-button" (click)="openFileforCollaboration(); openDocumentDropDown.close()">
              <span translate="no" class="material-symbols-outlined icon">workspaces</span>
              <span>Åpne for sammarbeid</span>
            </button>

          </div>
        </igx-card-content>
      </igx-card>
    </div>
  </igx-drop-down>

  <igx-drop-down #sharepointDisconnectedDropdown>
    <div class="integration-card">
      <img width="200px" [src]="'../../../../../../assets/images/datalex_logo.png'">
      <strong>Du er ikke koblet til SharePoint</strong>
      <p>For å åpne <strong [igxTooltipTarget]="msoHint">alle typer &#9432;</strong> dokumenter må deres Datalex
        integreres med Microsoft SharePoint. </p>
      <div style="padding: 1rem;" #msoHint="tooltip" igxTooltip>Med alle typer dokumenter menes da alle andre typer enn
        PDF og notat, da disse kan åpnes uten SharePoint.</div>
      <p>Kontakt Datalex Software for å komme igang.</p>
      <button (click)="openIntegrationRequestMail()" igxButton igxRipple>Kom igang!</button>
    </div>
  </igx-drop-down>

  <igx-drop-down #documentActionDropdown>
    <div class="card-wrapper">
      <igx-card elevated>
        <igx-card-content>
          <div class="flex-col">
            @if(isSafari && pdfHREF !== '') {
            <a *ngIf="!isSharePointDependent" class="action-menu-button" [href]="pdfHREF" target="_blank"
              rel="noopener noreferrer" style="text-decoration:none;" (click)="openDocumentDropDown.close()">
              <span translate="no" class="material-symbols-outlined icon">file_open</span>
              <span>Åpne</span>
            </a>
            } @else {
            <button *ngIf="!isSharePointDependent" class="action-menu-button"
              (click)="openFileManager(); documentActionDropdown.close()">
              <span translate="no" class="material-symbols-outlined icon">file_open</span>
              <span>Åpne</span>
            </button>
            }
            <button class="action-menu-button" (click)="copyAndOpenDocument(); documentActionDropdown.close()">
              <span translate="no" class="material-symbols-outlined icon">content_copy</span>
              <span>Kopier og åpne</span>
            </button>
            <button class="action-menu-button" (click)="contextCheckInDocument(); documentActionDropdown.close()"
              [ngStyle]="{ 'pointer-events': selected && !!clickedCellRowData.InUseBy ? 'initial' : 'none', 'color': selected && !!clickedCellRowData.InUseBy ? '#000' : '#BCBCBC' }">
              <span translate="no" class="material-symbols-outlined icon">assignment_turned_in</span>
              <span>Sjekk inn</span>
            </button>
            <button class="action-menu-button" (click)="contextOpenDetails(); documentActionDropdown.close()">
              <span translate="no" class="material-symbols-outlined icon">info</span>
              <span>Detaljer</span>
            </button>
            <button class="action-menu-button" (click)="openDocumentCategoryDialog(); documentActionDropdown.close()">
              <span translate="no" class="material-symbols-outlined icon">edit</span>
              <span>Kategori</span>
            </button>
            <button class="action-menu-button" (click)="contextDownloadDocument(); documentActionDropdown.close()">
              <span translate="no" class="material-symbols-outlined icon">file_save</span>
              <span>Last ned</span>
            </button>
            <button class="action-menu-button" (click)="contextDeleteDocument(); documentActionDropdown.close()">
              <span translate="no" class="material-symbols-outlined icon">delete</span>
              <span>Slett</span>
            </button>
          </div>
        </igx-card-content>
      </igx-card>
    </div>
  </igx-drop-down>

  <div>
    <ng-container #outlet [ngTemplateOutlet]="content">
    </ng-container>
  </div>

  <ng-template #content>

    <igx-dialog #DetailModal [closeOnOutsideSelect]="false" leftTopLabel="OK"
      (leftBottomSelect)="DetailModal.close(); resetDetailModal(); ">
      <igx-dialog-title>
        <div class="dialog-title-container">
          <div class="dialog-title">Dokumentdetaljer</div>
          <i translate="no" class="material-symbols-outlined"
            (click)="DetailModal.close(); resetDetailModal()">close</i>
        </div>
      </igx-dialog-title>
      @if(showDetailModal){
      <app-document-detail-editor [data]="clickedCellRowData" [note]="note" [rowId]="rowId"
        [subCategories]="documentSubCategories" [gotDocumentFullPermissions]="gotDocumentFullPermissions"
        (close)="DetailModal.close(); resetDetailModal();" (update)="loadDocuments()"
        (openFileEvent)="openFile()"></app-document-detail-editor>
      }
    </igx-dialog>


  </ng-template>

  <igx-dialog #CategoryModal [closeOnOutsideSelect]="false" leftTopLabel="OK"
    (leftBottomSelect)="CategoryModal.close(); resetCategoryModal(); ">
    <igx-dialog-title>
      <div class="dialog-title-container document-template-title">
        <div class="dialog-title">Dokumentkategorier</div>
        <i translate="no" class="material-symbols-outlined"
          (click)=" CategoryModal.close(); resetCategoryModal();">close</i>
      </div>
    </igx-dialog-title>
    @if(showCategoryModal){
    <app-document-category-editor [data]="clickedCellRowData" (categoryOutput)="SaveCategoryOutputFromModal($event)"
      (closeCategoryModal)="CategoryModal.close()"></app-document-category-editor>
    }

  </igx-dialog>

  <igx-dialog #NewDocumentFromTemplateModal [closeOnOutsideSelect]="false" leftTopLabel="OK"
    (leftBottomSelect)="NewDocumentFromTemplateModal.close() ; resetShowNewDocumentFromTemplateModal()"
    (onOpening)="disableScroll()" (onClosed)="enableScroll()">
    <igx-dialog-title>
      <div class="dialog-title-container">
        <div class="dialog-title">Dokumentkategorier</div>
        <i translate="no" class="material-symbols-outlined"
          (click)="NewDocumentFromTemplateModal.close(); resetShowNewDocumentFromTemplateModal()">close</i>
      </div>
    </igx-dialog-title>
    @if(showNewDocumentFromTemplateModal) {
    <app-document-from-template [case]="case" (onClose)="NewDocumentFromTemplateModal.close()" />
    }
  </igx-dialog>

  <!--[cellSelection]="'none'" [rowSelection]="'multiple'"-->

  <div class="document-grid-wrapper">
    <!-- <igx-grid igxPreventDocumentScroll appGridCount appGridDownloadProgress [progressCurrent]="downloadProgress ?? 0"
      #grid1 [autoGenerate]="false" [data]="datasource" [primaryKey]="'Id'" (contextMenu)="rightClick($event)"
      (doubleClick)="openFile()" (selected)="cellSelection($event)" [rowSelection]="'single'" [allowFiltering]="true" (cellEdit)="handleCellEdit($event)"
      height="100%" width="100%" hideRowSelectors="true" [emptyGridMessage]="emptyGridMessage"
      emptyFilteredGridMessage="Filtrering gir ingen treff" [rowStyles]="rowStyles" (gridScroll)="disableContextMenu()"> 
      
      (selected)="cellSelection($event)" 
      -->

    <igx-grid igxPreventDocumentScroll appGridCount appGridDownloadProgress [progressCurrent]="downloadProgress ?? 0"
      #grid1 [autoGenerate]="false" [data]="filteredDatasource" [primaryKey]="'Id'" (contextMenu)="rightClick($event)"
      (doubleClick)="openFile()" cellSelection='none' (rowSelectionChanging)="cellSelection($event)"
      [rowSelection]="'single'" [allowFiltering]="true" (cellEdit)="handleCellEdit($event)" height="100%" width="100%"
      hideRowSelectors="true" [emptyGridMessage]="emptyGridMessage" [style.--ig-size]="'var(--ig-size-small)'"
      emptyFilteredGridMessage="Filtrering gir ingen treff" [rowStyles]="rowStyles" (gridScroll)="disableContextMenu()"
      [isLoading]="isLoading">


      <igx-column field="Icon" header="&nbsp;" [width]="'32px'" [filterable]="false" [headerClasses]="'header-filter'">
        <ng-template igxHeader let-column>
          <div>
            <igx-icon id="clear-filter" class="material-symbols-outlined grid-filter-clear-button"
              [appClearGridFilters]="grid1" #target="tooltipTarget"
              [igxTooltipTarget]="tooltipRef">filter_alt_off</igx-icon>
            <div class="tooltip" #tooltipRef="tooltip" igxTooltip>
              Klikk her for å blanke ut alle filter kriterier.
            </div>
          </div>
        </ng-template>
        <ng-template igxCell let-cell="cell">
          <div class="icon-cell-inner">
            <img [src]="cell.value" class="fileicon" />
          </div>
        </ng-template>
      </igx-column>
      @if (!isMobile) {
      <igx-column field="HasAttachment" header="&#x1F4CE;" width="32px" [dataType]="'boolean'"
        [filterCellTemplate]="defaultFilterTemplate" [sortable]="true">
        <ng-template igxHeader let-column>
          <igx-icon class="att_header">attach_file</igx-icon>
        </ng-template>
        <ng-template igxCell let-cell="cell">

          <div class="custom-cell">
            <span translate="no" class="material-symbols-outlined center">{{cell.row.data.HasAttachment ? 'attach_file'
              : '' }}</span>
          </div>
        </ng-template>
      </igx-column>
      }
      @if (!isMobile) {
      <igx-column field="IsOut" header="Inn/Ut" width="65px" [sortable]="true" dataType="boolean"
        [filterCellTemplate]="defaultFilterTemplate">
        <ng-template igxCell let-cell="cell">
          <div class="custom-cell">
            <span class="center">{{cell.row.data.IsOut ? 'U' : 'I' }}</span>
          </div>
        </ng-template>
      </igx-column>
      }

      @if(!isMobile && isDesktopDevice) {
      <ng-container>

        <igx-column field="DocumentCategoryName" header="Kategori" dataType="string" [sortable]="true"
          [filterCellTemplate]="defaultFilterTemplate" [width]="'148px'">
          <ng-template igxCell let-cell="cell">
            <div class="custom-cell">
              <div [class]="cell.value ? 'grid-cell-open' : 'grid-cell-open-empty'">
                <span class="grid-cell-text">{{cell.value ?? ''}}</span>
                @if(canEditCategory(cell.row.data)) {
                <span class="material-icons grid-icon-style"
                  (click)="openDocumentCategoryDialog(cell.row.data)">{{"open_in_new"}}</span>
                }
              </div>
            </div>
          </ng-template>
        </igx-column>
        <igx-column field="DocumentSubCategoryName" header="Underkategori" dataType="string" [sortable]="true"
          [filterCellTemplate]="defaultFilterTemplate" [width]="'148px'">
          <ng-template igxCell let-cell="cell">

            <div class="custom-cell">

              <div [class]="cell.value ? 'grid-cell-open' : 'grid-cell-open-empty'">
                <span class="grid-cell-text">{{cell.value ?? ''}}</span>
                @if(canEditCategory(cell.row.data)) {
                <span class="material-icons grid-icon-style"
                  (click)="openDocumentCategoryDialog(cell.row.data)">{{"open_in_new"}}</span>
                }
              </div>

            </div>
          </ng-template>
        </igx-column>
      </ng-container>

      }

      <igx-column field="DocumentName" header="Tittel" [sortable]="true" dataType="string"
        [filterCellTemplate]="defaultFilterTemplate" />

      @if (!isMobile) {
      <igx-column field="DateChanged" header="Sist endret" [pipeArgs]="dateTimeOptions" [width]="'128px'"
        [sortable]="true" [dataType]="'dateTime'" [filterCellTemplate]="defaultFilterTemplate" />
      }

      <igx-column field="AuthorName" [width]="isMobile ? '80px': '150px'" header="Avsender / Forfatter"
        dataType="string" [sortable]="true" [filterCellTemplate]="defaultFilterTemplate" />
      <igx-column [width]="isMobile ? '80px': '120px'" field="InUseBy" header="Låst av" dataType="string"
        [sortable]="true" [filterCellTemplate]="defaultFilterTemplate" />

      @if (!isMobile) {
      <ng-container>
        <igx-column field="DocNr" header="Dok.nr." [sortable]="true" dataType="string" width="80px"
          [filterCellTemplate]="defaultFilterTemplate" />

        <igx-column field="RecipientName" header="Adressat" width="130px" [sortable]="true" dataType="string"
          [filterCellTemplate]="defaultFilterTemplate" />
      </ng-container>
      }

      @if(!isMobile && !isDesktopDevice) {
      <ng-container>
        <igx-column field="DocumentCategoryName" header="Kategori" dataType="string" [sortable]="true"
          [filterCellTemplate]="defaultFilterTemplate" [width]="'148px'">
          <ng-template igxCell let-cell="cell">
            <div class="custom-cell">

              <div *ngIf="cell.value" class="grid-cell-open">
                <span class="grid-cell-text">{{cell.value}}</span>
                <span class="material-icons grid-icon-style"
                  (click)="openDocumentCategoryDialog(cell.row.data)">{{"open_in_new"}}</span>
              </div>

              <div *ngIf="!cell.value" class="grid-cell-open-empty">
                <span class="material-icons grid-icon-style"
                  (click)="openDocumentCategoryDialog(cell.row.data)">{{"open_in_new"}}</span>
              </div>

            </div>
          </ng-template>
        </igx-column>
        <igx-column field="DocumentSubCategoryName" header="Underkategori" dataType="string" [sortable]="true"
          [filterCellTemplate]="defaultFilterTemplate" [width]="'148px'">
          <ng-template igxCell let-cell="cell">

            <div class="custom-cell">

              <div *ngIf="cell.value" class="grid-cell-open">
                <span class="grid-cell-text">{{cell.value}}</span>
                <span class="material-icons grid-icon-style"
                  (click)="openDocumentCategoryDialog(cell.row.data)">{{"open_in_new"}}</span>
              </div>

              <div *ngIf="!cell.value" class="grid-cell-open-empty">
                <span class="material-icons grid-icon-style"
                  (click)="openDocumentCategoryDialog(cell.row.data)">{{"open_in_new"}}</span>
              </div>

            </div>
          </ng-template>
        </igx-column>
      </ng-container>
      }

      @if (!isMobile) {
      <ng-container>
        <igx-column field="DocumentTypeName" [header]="'Type'" [width]="'120px'" [dataType]="'string'"
          [filterCellTemplate]="defaultFilterTemplate" [sortable]="true" />
        <igx-column field="Filename" width="150px" header="Filnavn" [sortable]="true" dataType="string"
          [filterCellTemplate]="defaultFilterTemplate" />
        <igx-column field="FileSizeInMB" header="Størrelse" [sortable]="true" dataType="string" [width]="'90px'"
          [filterCellTemplate]="defaultFilterTemplate" />
      </ng-container>
      }

      <igx-grid-footer />
      <!-- 
      <igx-grid-footer class="grid-footer-container">
        <dlx-grid-item-count [isMobile]="isMobile" [current]="grid1.totalRowsCountAfterFilter"
          [total]="datasource.length" />
      </igx-grid-footer> -->

    </igx-grid>

  </div>

  <div *ngIf="contextmenu">
    <grid-context-menu #gridContextMenu [x]="contextmenuX" [y]="contextmenuY" [left]="left" [right]="right"
      [subLeft]="subLeft" [content]="ctx_content">
    </grid-context-menu>
  </div>

  <igx-dialog #imageDialog closeOnOutsideSelect="true" style="max-width: 95vw; max-height: 90vw;"
    (rightTopButton)="imageDialog.close()">
    <igx-dialog-title>
      <div class="dialog-title-container">
        @if (clickedCellRowData) {
        <span>{{clickedCellRowData.DocumentName}}</span>
        }
        <i translate="no" class="material-symbols-outlined" (click)="imageDialog.close()">close</i>
      </div>
    </igx-dialog-title>
    <img [src]="decodedImageUrl" alt="Image preview" style="max-width: 90vw; max-height: 70vh;">
    <div igxDialogActions>
      <button igxButton (click)="contextDownloadDocument(false); imageDialog.close()" igxButton="contained"
        [style.color]="'white'" [style.background]="'#AEC965'" igxRipple="white">Last ned</button>
    </div>
  </igx-dialog>


  <ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
    <grid-filter-input [grid]="grid1" [column]="column"></grid-filter-input>
  </ng-template>
</div>