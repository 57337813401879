import { Injectable } from '@angular/core';
import { DatalexMsalService } from './datalex-msal.service';
import { DatalexClient, IDocumentBE, IDocumentLimitedBE, IDocumentPack, IDocumentSubCategoryBE, UserAreaEnum, UserRightTypeEnum } from '@datalex-software-as/datalex-client';
import { Router } from '@angular/router';
import { DocumentCheckoutService, WindowWrapper } from '../components/UI/document-checkout/document-checkout.service';
import { SystemCacheService } from './system-cache.service';
import { ActivityLogService } from '../components/UI/activity-log-overlay/activity-log.service';
import newActivity, { ActivityType } from '../classes/Activity/Actvity';
import { firstValueFrom, Observable, Subscription, switchMap } from 'rxjs';
import { SystemDialogEnum } from '../util/SystemDialogEnum';
import { UserRightsProviderService } from './user-rights-provider.service';
import { DeviceService } from './device.service';
import { DocumentTypeEnum, ProtectedDocumentTypes } from '../util/DocumentTypesUtil';
import { MicrosoftGraph } from '../classes/MicrosoftGraph';
import { DriveItem } from 'src/models/MicrosoftGraphReturnTypes';
import FileManager from '../classes/FileManager';
import { getMimeTypeFromFilename } from '../util/FileMimeTypeUtil';

@Injectable({
  providedIn: 'root'
})
export class DocumentHandlerService {

  showDetailModal: boolean = false;
  documentDetails: IDocumentBE | null = null;
  documentSubCategories: IDocumentSubCategoryBE[] = [];
  subscriptions = new Subscription();
  isLoggedInSharepoint: boolean = false


  constructor(private dlxMsal: DatalexMsalService,
    private dlxClient: DatalexClient,
    private router: Router,
    private dcs: DocumentCheckoutService,
    private als: ActivityLogService,
    private sys: SystemCacheService,
    public userRights: UserRightsProviderService,
    public deviceService: DeviceService) {


    this.subscriptions.add(this.dlxMsal.isLoggedInToSharePoint$.subscribe({
      next: value => {
        if (value === null) return
        this.isLoggedInSharepoint = value.isLoggedIn;
      }
    }));
  }



  unlockDocuments(documents: IDocumentLimitedBE[]): Observable<IDocumentLimitedBE> {
    let iterations = 0;

    return new Observable<IDocumentLimitedBE>((observer) => {

      const checkCompletion = () => {
        if (iterations === documents.length) {
          observer.complete();
        }
      };

      // Iterate through the selected documents
      documents.forEach(document => {
        iterations++;

        const isCheckedOutInSharePoint = !!this.dcs.getCheckedoutDocument(document.Id);

        if (isCheckedOutInSharePoint) {
          // If the document is opened in SharePoint, log and skip unlocking
          this.logUnlockActivity(document, 'warning');
          checkCompletion();
        } else {
          // If the document is not checked out in SharePoint, proceed to unlock the document
          if (!document.FileId) {
            return;
          }

          this.dlxClient.GetFileLockByFileId(document.FileId).subscribe({
            next: (res) => {

              this.dlxClient.DeleteFileLock(res.Id).subscribe({
                next: () => {
                  this.logUnlockActivity(document, 'success');

                  document.InUseBy = "";
                  // Notify the observer with the unlocked document
                  observer.next(document);

                  checkCompletion();
                },
                error: (err) => {
                  this.logUnlockActivity(document, 'failure', err);
                  checkCompletion();
                }
              });
            },
            error: (err) => {
              this.logUnlockActivity(document, 'failure', err);
              checkCompletion();
            }
          });
        }
      });
    });
  }

  // deleteDocuments(documents: IDocumentLimitedBE[]): Observable<IDocumentLimitedBE> {
  //   let iterations = 0;

  //   return new Observable<IDocumentLimitedBE>((observer) => {
  //     // Separate documents based on permission check
  //     const documentsToDelete = documents.filter(doc => this.canDeleteDocument(doc));
  //     const restrictedDocuments = documents.filter(doc => !documentsToDelete.includes(doc));

  //     // Notify about restricted documents and log the activity
  //     if (restrictedDocuments.length > 0) {
  //       const restrictedDocumentNames = restrictedDocuments.map(doc => doc.DocumentName).join(', ');
  //       const message = `Du har ikke rettigheter til å slette: ${restrictedDocumentNames}.`;

  //       const activity = newActivity({
  //         message,
  //         type: 'warning',
  //         timestamp: new Date(),
  //       });

  //       this.als.appendActivity(activity);
  //       observer.error(message);
  //     }


  //     // If there are no documents to delete, complete the observable
  //     if (documentsToDelete.length === 0) {
  //       observer.complete();
  //       return;
  //     }

  //     // Iterate over the documents and delete them
  //     documentsToDelete.forEach((document) => {
  //       iterations++;
  //       const isCurrentIterationLast = iterations === documentsToDelete.length;

  //       // Check if the document is protected
  //       const isProtected = ProtectedDocumentTypes.some((type) => type.toUpperCase() === document.DocumentTypeId.toUpperCase());
  //       if (isProtected) {
  //         const message = SystemDialogEnum.PROTECTED_DOCUMENT_DELETE_ERROR.replace('*doc_name*', document.DocumentName!);
  //         const activity = newActivity({
  //           message,
  //           type: 'failure',
  //           timestamp: new Date(),
  //         });
  //         this.als.appendActivity(activity);
  //         observer.error(message);
  //         return;
  //       }

  //       // Proceed with deletion
  //       this.dlxClient.DeleteDocument(document.Id).subscribe({
  //         next: () => {
  //           observer.next(document);
  //           const activity = newActivity({
  //             message: `Dokumentet ${document.DocumentName} ble slettet.`,
  //             type: 'success',
  //             timestamp: new Date(),
  //           });
  //           this.als.appendActivity(activity);

  //           if (isCurrentIterationLast) {
  //             observer.complete();
  //           }
  //         },
  //         error: (error) => {
  //           let message = SystemDialogEnum.DOCUMENT_DELETE_ERROR.replace('*doc_name*', document.DocumentName!);
  //           if (error.error.includes('The DELETE statement conflicted with the REFERENCE constraint "FK_OutgoingInvoice_"')) {
  //             message = SystemDialogEnum.ACCOUNTING_DOCUMENT_DELETE_ERROR;
  //           }

  //           const activity = newActivity({
  //             message,
  //             type: 'failure',
  //             timestamp: new Date(),
  //           });
  //           this.als.appendActivity(activity);
  //           observer.error(message);
  //         }
  //       });
  //     });
  //   });
  // }

  deleteDocuments(documents: IDocumentLimitedBE[]): Observable<IDocumentLimitedBE> {
    return new Observable<IDocumentLimitedBE>((observer) => {
      // Separate documents based on permission check
      const documentsToDelete = documents.filter(doc => this.canDeleteDocument(doc));
      const restrictedDocuments = documents.filter(doc => !documentsToDelete.includes(doc));

      // Notify about restricted documents and log the activity
      if (restrictedDocuments.length > 0) {
        const restrictedDocumentNames = restrictedDocuments.map(doc => doc.DocumentName).join(', ');
        const message = `Du har ikke rettigheter til å slette: ${restrictedDocumentNames}.`;

        const activity = newActivity({
          message,
          type: 'warning',
          timestamp: new Date(),
        });

        this.als.appendActivity(activity);
      }

      // If there are no documents to delete, complete the observable
      if (documentsToDelete.length === 0) {
        observer.complete();
        return;
      }

      let processedCount = 0; // Tracks successfully processed documents

      // Iterate over the documents and delete them
      documentsToDelete.forEach((document) => {
        // Check if the document is protected
        const isProtected = ProtectedDocumentTypes.some((type) => type.toUpperCase() === document.DocumentTypeId.toUpperCase());
        if (isProtected) {
          const message = SystemDialogEnum.PROTECTED_DOCUMENT_DELETE_ERROR.replace('*doc_name*', document.DocumentName!);
          const activity = newActivity({
            message,
            type: 'failure',
            timestamp: new Date(),
          });
          this.als.appendActivity(activity);

          // Increment processed count for protected documents
          processedCount++;
          if (processedCount === documentsToDelete.length) {
            observer.complete();
          }
          return;
        }

        // Proceed with deletion
        this.dlxClient.DeleteDocument(document.Id).subscribe({
          next: () => {
            observer.next(document);
            const activity = newActivity({
              message: `Dokumentet ${document.DocumentName} ble slettet.`,
              type: 'success',
              timestamp: new Date(),
            });
            this.als.appendActivity(activity);
          },
          error: (error) => {
            let message = SystemDialogEnum.DOCUMENT_DELETE_ERROR.replace('*doc_name*', document.DocumentName!);
            if (error.error?.includes('The DELETE statement conflicted with the REFERENCE constraint "FK_OutgoingInvoice_"')) {
              message = SystemDialogEnum.ACCOUNTING_DOCUMENT_DELETE_ERROR;
            }
            if (error.error?.includes('The DELETE statement conflicted with the REFERENCE constraint \"FK_DigipostDocument')) {
              message = SystemDialogEnum.DIGIPOST_DOCUMENT_DELETE_ERROR;
            }

            if (error.error?.includes('The DELETE statement conflicted with the REFERENCE constraint')) {
              message = SystemDialogEnum.PROTECTED_DOCUMENT_DELETE_ERROR;
            }


            const activity = newActivity({
              message,
              type: 'failure',
              timestamp: new Date(),
            });
            this.als.appendActivity(activity);
          },
          complete: () => {
            processedCount++;
            if (processedCount === documentsToDelete.length) {
              observer.complete();
            }
          },
        });
      });
    });
  }



  public canDeleteDocument(doc: IDocumentLimitedBE): boolean {
    // Check if the document is protected first
    const isProtected = ProtectedDocumentTypes.some(
      (type) => type.toUpperCase() === doc.DocumentTypeId.toUpperCase()
    );

    if (isProtected) {
      return false;
    }

    if (doc.CaseNumber) {
      return this.checkCaseDocumentFullRights();
    } else {
      return this.checkContactDocumentFullRights();
    }
  }


  public canOpenDocument(document: IDocumentLimitedBE): boolean {
    if (document.CaseNumber) {
      return this.checkCaseDocumentFullRights();
    } else {
      return this.checkContactDocumentFullRights();
    }
  }

  private checkCaseDocumentFullRights(): boolean {
    const accessLevel = this.userRights.checkAccess(UserAreaEnum.CASE_DOCUMENTS).accessId;
    return accessLevel === UserRightTypeEnum.FULL;
  }

  private checkContactDocumentFullRights(): boolean {
    const accessLevel = this.userRights.checkAccess(UserAreaEnum.CONTACT_DOCUMENTS).accessId;
    return accessLevel === UserRightTypeEnum.FULL;
  }


  private addWindowHandle({ window, windowId, userId, parentRefrence }: WindowWrapper) {
    this.dcs.addPopupHandle({ window, windowId, userId, parentRefrence })
  }

  private handleOpenFileError(rowData: IDocumentLimitedBE) {
    const activity = newActivity({
      message: `Kunne ikke åpne dokumentet ${rowData.DocumentName}.`,
      type: 'failure',
      timestamp: new Date(),
    });
    this.als.appendActivity(activity);
  }

  private createReadOnlyUrl(url: string): string {
    const readOnlyUrl = url.replace('action=default', 'action=view').replace('action=edit', 'action=view');
    return readOnlyUrl;
  }


  openMsoDocumentCollaboration(data: IDocumentLimitedBE) {
    const waitingActivity = newActivity({
      message: `Venter på dokumentet ${data.DocumentName}.`,
      type: 'info',
      timestamp: new Date(),
    });

    this.als.appendActivity(waitingActivity);

    this.dlxMsal.useToken(this.router.url).subscribe({
      next: (token) => {
        this.dlxClient.UploadDocumentSharePoint(data.Id, true, true, token).subscribe({
          next: (response) => {
            const collabWindow = window.open(response.WebUrl, '_blank');
            if (!collabWindow) {
              this.handleOpenFileError(data)
            } else {
              this.addWindowHandle({ window: collabWindow, windowId: data.Id, userId: this.sys.userId });
              this.als.removeActivity(waitingActivity);
              this.dcs.addDocumentSharePoint(data.Id, true);
            }
          },
          error: () => {
            this.handleOpenFileError(data)
            this.als.removeActivity(waitingActivity);
          },
        });
      },
      error: () => {
        this.newOpenFailureActivity('Kunne ikke hente token for dokumentet.');
        this.als.removeActivity(waitingActivity);
      },
    });
  }

  private newOpenFailureActivity(message: string) {
    const activity = newActivity({
      message,
      type: 'failure',
      timestamp: new Date(),
    });
    this.als.appendActivity(activity);
  }

  openDocuments(documents: IDocumentLimitedBE[]): Observable<IDocumentLimitedBE> {

    let iterations = 0;

    return new Observable<IDocumentLimitedBE>((observer) => {

      const checkCompletion = () => {

        if (iterations === documents.length) {
          observer.complete();
        }
      };

      documents.forEach((document) => {
        iterations++;
        if (document.DocumentTypeId.toUpperCase() === DocumentTypeEnum.DIGIPOST.toUpperCase()) {
          checkCompletion();
          return;
        }

        // Check if the document is already open in another window
        if (this.dcs.isWindowOpen(document.Id)) {
          checkCompletion();
          return;
        }

        if (!this.canOpenDocument(document)) {
          observer.error(`Du har ikke rettigheter til å se Kontakt dokumenter`);
          checkCompletion();
          return;
        }

        const hasFullRights = this.checkDocumentFullRights(document.CaseNumber ? UserAreaEnum.CASE_DOCUMENTS : UserAreaEnum.CONTACT_DOCUMENTS);
        const fileExtension = this.getFileExtension(document.Filename);

        switch (fileExtension) {
          case "pdf":

            this.handlePdfFile(document);
            observer.next(document);
            checkCompletion();
            break;

          case "jpg":
          case "jpeg":
          case "png":
          case "webp":

            observer.next(document);
            checkCompletion();
            break;

          default:
            if (!this.isLoggedInSharepoint) {
              checkCompletion();
              observer.error(`Failed to open document ${document.DocumentName}: SharePoint not logged in.`);
              return
            }
            this.openMsoDefaultBehavior(document, !hasFullRights).subscribe({
              next: () => {

                document.InUseBy = this.sys.loggedinUserContact.Name;
                observer.next(document);
                checkCompletion();
              },
              error: (error) => {
                observer.error(`Failed to open document ${document.DocumentName}: ${error}`);
              }
            });
            break;
        }
      });

    });
  }


  private openMsoDefaultBehavior(document: IDocumentLimitedBE, readOnly: boolean): Observable<IDocumentLimitedBE> {
    return new Observable<IDocumentLimitedBE>((observer) => {
      if (readOnly || document.Filename?.toLowerCase().endsWith('.msg')) {
        this.openMsoDocumentReadOnly(document).subscribe({
          next: () => observer.next(document),
          error: (error) => observer.error(error)
        });
      } else {
        this.openMsoDocumentEdit(document).subscribe({
          next: () => observer.next(document),
          error: (error) => observer.error(error)
        });
      }
    });
  }

  private handlePdfFile(document: IDocumentLimitedBE): void {
    this.dlxClient.FileLinearizePDF(document.FileId!).subscribe(() => {
      const url = `/pdfviewer/${document.Id}`;
      const pdfWindow = window.open(url, '_blank');
      this.dcs.addPopupHandle({ window: pdfWindow!, windowId: document.Id, userId: this.sys.userId });
    });
  }

  private openMsoDocumentEdit(document: IDocumentLimitedBE): Observable<void> {
    return new Observable<void>((observer) => {
      const waitingActivity = newActivity({
        message: `Venter på dokumentet ${document.DocumentName}.`,
        type: 'info',
        timestamp: new Date(),
      });

      this.als.appendActivity(waitingActivity);

      this.dlxMsal.useToken(window.location.href).subscribe({
        next: (token) => {
          this.dlxClient.UploadDocumentSharePoint(document.Id, true, false, token).subscribe({
            next: (response) => {
              const editWindow = window.open(response.WebUrl, '_blank');
              if (!editWindow) {
                observer.error(`Failed to open document ${document.DocumentName} in edit mode.`);
                this.handleOpenFileError(document);
              } else {
                this.dcs.addPopupHandle({ window: editWindow!, windowId: document.Id, userId: this.sys.userId });
                this.als.removeActivity(waitingActivity);
                this.dcs.addDocumentSharePoint(document.Id, true); // Editable
                observer.next();
              }
            },
            error: () => {
              observer.error(`Failed to upload document ${document.DocumentName} for editing.`);
              this.handleOpenFileError(document);
              this.als.removeActivity(waitingActivity);
            }
          });
        },
        error: () => {
          observer.error(`Failed to retrieve token for document ${document.DocumentName}.`);
          this.newOpenFailureActivity('Kunne ikke hente token for dokumentet.');
          this.als.removeActivity(waitingActivity);
        }
      });
    });
  }

  private openMsoDocumentReadOnly(document: IDocumentLimitedBE): Observable<void> {
    return new Observable<void>((observer) => {
      const waitingActivity = newActivity({
        message: `Venter på dokumentet ${document.DocumentName}.`,
        type: 'info',
        timestamp: new Date(),
      });

      this.als.appendActivity(waitingActivity);

      this.dlxMsal.useToken(window.location.href).subscribe({
        next: (token) => {
          this.dlxClient.UploadDocumentSharePointRead(document.Id, token).subscribe({
            next: (response) => {
              let viewUrl;
              if (document.Filename?.toLowerCase().endsWith('.msg')) {
                viewUrl = response.PreviewUrl;
              } else {
                viewUrl = this.createReadOnlyUrl(response.WebUrl);
              }
              const viewWindow = window.open(viewUrl, '_blank');

              if (!viewWindow) {
                observer.error(`Failed to open document ${document.DocumentName} in read-only mode.`);
                this.handleOpenFileError(document);
              } else {
                this.dcs.addPopupHandle({ window: viewWindow!, windowId: document.Id, userId: this.sys.userId });
                this.als.removeActivity(waitingActivity);
                this.dcs.addDocumentSharePoint(document.Id, false); // Not editable
                observer.next();
              }
            },
            error: () => {
              observer.error(`Failed to upload document ${document.DocumentName} for read-only mode.`);
              this.handleOpenFileError(document);
              this.als.removeActivity(waitingActivity);
            }
          });
        },
        error: () => {
          observer.error(`Failed to retrieve token for document ${document.DocumentName}.`);
          this.newOpenFailureActivity('Kunne ikke hente token for dokumentet.');
          this.als.removeActivity(waitingActivity);
        }
      });
    });
  }

  private getFileExtension(filename: string | undefined): string {
    if (!filename) return '';
    const split = filename.split('.');
    return split[split.length - 1].toLowerCase();
  }


  checkDocumentFullRights(userArea: UserAreaEnum): boolean {
    const documentAccesslevel = this.userRights.checkAccess(userArea).accessId;
    if (documentAccesslevel === UserRightTypeEnum.NOT_LOADED) return false;
    if (documentAccesslevel === UserRightTypeEnum.LOOK) return false;
    if (documentAccesslevel === UserRightTypeEnum.FULL) return true;
    return false;
  }

  openPdf(data: IDocumentLimitedBE): void {
    // Detect the browser type and device type
    const isSafari = this.deviceService.getBrowserType() === 'Safari';
    const isDesktop = this.deviceService.getDeviceType() === 'Desktop';

    // Check if the document is already open in another window
    const existingHandle = this.dcs.popupHandleLookup(data.Id);
    if (existingHandle) {
      existingHandle.window.focus();
      return;
    }

    // Request to linearize the PDF before opening it
    this.dlxClient.FileLinearizePDF(data.FileId!).subscribe({
      next: () => {
        const url = this.router.serializeUrl(
          this.router.createUrlTree([`/pdfviewer/${data.Id}`])
        );

        // Automatically handle Safari or desktop cases
        const handle = isSafari ? this.openLinkSafari(url, data) : this.openPdfStandard(url, data);

        // If the popup fails to open, notify the user
        if (!handle) {
          alert('Popup window was blocked by the browser. Please allow popups and try again.');
          console.error('Failed to open window. Popup blocked or another issue occurred.');
          return;
        }

        // Add the window handle for tracking
        this.addWindowHandle({
          window: handle,
          windowId: data.Id,
          userId: this.sys.userId
        });

      },
      error: (err) => {
        console.error('Error while linearizing PDF:', err);
        alert('Failed to prepare PDF for viewing.');
      }
    });
  }

  openPdfStandard(url: string, data: IDocumentLimitedBE): Window | null {
    const params = `status=no,location=no,toolbar=no,menubar=no`;
    let handle: Window | null = null;

    try {
      // Try to open the window based on device type
      handle = window.open(url, '_blank') || (this.deviceService.getDeviceType() === 'Desktop' ? window.open(url, data.Id, params) : null);

      if (!handle) {
        throw new Error("Popup blocked or window opening failed");
      }

      return handle;
    } catch (error) {
      console.error('Error opening PDF in standard mode:', error);
      return null;
    }
  }

  openLinkSafari(url: string, data: IDocumentLimitedBE): Window | null {
    try {
      // Safari-specific logic to open a new tab or window
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank'; // Opens in a new tab or window
      link.rel = 'noopener noreferrer'; // Prevents some security risks

      document.body.appendChild(link);

      // Simulate a user click to open the link
      link.click();
      document.body.removeChild(link);

      return null; // Safari does not return a window handle
    } catch (error) {
      console.error('Error opening PDF in Safari:', error);
      return null;
    }
  }

  logUnlockActivity(document: IDocumentLimitedBE, type: 'success' | 'warning' | 'failure', errorMessage?: string) {
    let activityMessage: string = '';

    if (type === 'success') {
      activityMessage = `Dokumentet ${document.DocumentName} ble låst opp.`;
    } else if (type === 'warning') {
      activityMessage = `Dokumentet ${document.DocumentName} er åpnet i SharePoint.`;
    } else if (type === 'failure') {
      activityMessage = `Dokumentet ${document.DocumentName} kunne ikke låses opp.`

    }

    const activity = newActivity({
      message: activityMessage,
      type,
      timestamp: new Date(),
    });

    this.als.appendActivity(activity);
  }

  copyAndOpenDocument(document: IDocumentLimitedBE, targetCaseId: string): Observable<IDocumentLimitedBE> {
    return new Observable<IDocumentLimitedBE>((observer) => {

      if (!this.canOpenDocument(document)) {
        const activity = newActivity({
          message: `Du har ikke rettigheter til å se dokumenter i denne saken.`,
          type: 'failure',
          timestamp: new Date(),
        });
        this.als.appendActivity(activity);
        observer.error('Du har ikke rettigheter til å se dokumenter i denne saken.');
        return;
      }


      this.dlxClient.CopyDocumentToCase(document.Id, targetCaseId).subscribe({
        next: (copiedDocument: IDocumentBE) => {



          this.dlxClient.GetDocumentLimited(copiedDocument.Id).subscribe({
            next: (copiedDocumentLimited) => {

              if (document.DocumentTypeId.toUpperCase() === DocumentTypeEnum.NOTAT.toUpperCase()) {
                observer.next(copiedDocumentLimited);

              } else {
                this.openDocuments([copiedDocumentLimited]).subscribe({
                  next: (openedDocument) => {
                    observer.next(openedDocument);
                  },
                  error: (err) => {
                    console.error('Error opening copied document:', err);
                    observer.error(`Error opening copied document: ${err}`);
                  },
                });
              }
            }
          })

        },
        error: (err) => {
          const errorMessage = `Error copying document ${document.DocumentName}: ${err}`;
          console.error(errorMessage);
          observer.error(errorMessage);
        },
      });
    });
  }

  convertToPdf(document: IDocumentLimitedBE): Observable<void> {
    return new Observable((observer) => {

      const { DocumentName, CaseId, ContactId, Id } = document;

      if (!this.sys.microsoftGraphClientConfig) return;

      const graph = new MicrosoftGraph({
        authority: this.sys.microsoftGraphClientConfig.authority,
        clientId: this.sys.microsoftGraphClientConfig.clientId,
        scopes: this.sys.microsoftGraphClientConfig.clientScopes
      });

      graph.initiateClient().then((status) => {
        if (!status.success || !graph.client) return;

        this.dlxMsal.useToken(this.router.url).subscribe({
          next: (token) => {
            const infoActivity = newActivity({
              message: `Konverterer ${DocumentName} til PDF.`,
              type: 'info',
              timestamp: new Date(),
            });
            this.als.appendActivity(infoActivity);

            this.dlxClient.UploadDocumentSharePointRead(Id, token).subscribe({
              next: (uploadedDocument) => {
                const driveItem = uploadedDocument as DriveItem;

                graph.client!.api(`/drives/${driveItem.ParentReference.DriveId}/items/${driveItem.Id}/content?format=pdf`)
                  .getStream()
                  .then(async (response) => {
                    this.als.removeActivity(infoActivity);
                    const successActivity = newActivity({
                      message: `${DocumentName} konvertert til PDF.`,
                      type: 'success',
                      timestamp: new Date(),
                    });
                    this.als.appendActivity(successActivity);

                    const documentPack = await this.createAndUploadNewDocument(response, this.replaceExtension(driveItem.Name, '.pdf'), ContactId, CaseId);
                    if (!documentPack) {
                      throw new Error('Upload failed');
                    }

                    observer.next();  // Notify that the process succeeded
                    observer.complete();  // Mark observable as complete
                  })
                  .catch((error) => {
                    this.als.removeActivity(infoActivity);
                    this.als.appendActivity(newActivity({
                      message: `Kunne ikke konvertere ${DocumentName} til PDF.`,
                      type: 'failure',
                      timestamp: new Date(),
                    }));
                    console.error(error);
                    observer.error(error);  // Notify that an error occurred
                  });
              },
              error: (error) => {
                console.error('Error during document upload:', error);
                observer.error(error);
              }
            });
          },
          error: (error) => {
            console.error('Error fetching token:', error);
            observer.error(error);
          }
        });
      }).catch((error) => {
        console.error('Error initiating graph client:', error);
        observer.error(error);
      });
    });
  }


  async createAndUploadNewDocument(stream: ReadableStream, fileName: string, contactId?: string | null, caseId?: string | null): Promise<IDocumentPack> {
    return new Promise(async (resolve, reject) => {
      try {

        const fm = new FileManager();
        const newDocument = await firstValueFrom(this.dlxClient.NewDocument());
        if (!newDocument) return;
        const file = await this.readableStreamToFile(stream, fileName, getMimeTypeFromFilename(fileName));

        const document = fm.createDocumentWithDefaultValues(newDocument, file, this.sys.loggedinUserContact.Id, contactId, caseId);

        this.dlxClient.UploadDocument(document, file).subscribe({
          next: (uploadedDocument) => {
            resolve(uploadedDocument);
          }
        })
      } catch (error) {
        reject(error);
      }
    })
  }


  replaceExtension(filename: string, newExtension: string): string {
    // Find the last dot in the filename
    const dotIndex = filename.lastIndexOf('.');

    // If there's no dot, add the new extension at the end
    if (dotIndex === -1) {
      return `${filename}${newExtension}`;
    }

    // Slice the filename up to the last dot and append the new extension
    return `${filename.slice(0, dotIndex)}${newExtension}`;
  }

  async readableStreamToFile(stream: ReadableStream, fileName: string, mimeType: string): Promise<File> {
    // Create a reader for the stream
    const reader = stream.getReader();
    const chunks: Uint8Array[] = [];
    let done = false;

    // Read all chunks from the stream
    while (!done) {
      const { done: streamDone, value } = await reader.read();
      done = streamDone;
      if (value) {
        chunks.push(value);
      }
    }

    // Convert the chunks to a Blob
    const blob = new Blob(chunks, { type: mimeType });

    // Create a File from the Blob
    const file = new File([blob], fileName, { type: mimeType });

    return file;
  }

}
