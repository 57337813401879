<igx-dialog #dialog>
  <div class="dialog_header flex-row space-between">
    <strong [title]="images.length > 0 ? images[viewer.current].title : 'Laster inn bilder'">{{images.length > 0 ?
      images[viewer.current].title : 'Laster inn bilder'}}</strong>
    <div class="flex-row items-center">
      <div class="flex-row items-center">
        @if(images.length > 0) {
        <span translate="no" class="material-symbols-outlined pointer" (click)="prevImage()">chevron_left</span>
        <span>{{viewer.current + 1}} / {{images.length}}</span>
        <span translate="no" class="material-symbols-outlined pointer" (click)="nextImage()">chevron_right</span>
        }
      </div>
      <span translate="no" class="material-symbols-outlined pointer spacer download_icon" (click)="downloadImage()"
        title="Last ned">download</span>
      <span translate="no" class="material-symbols-outlined pointer close_icon" (click)="closeDialog()"
        title="Lukk">close</span>
    </div>
  </div>
  <div class="dialog_content">
    <igx-carousel #viewer [navigation]="false" [indicators]="false" [keyboardSupport]="true" class="dialog_carousel">
      @if(images.length > 0) {
      @for (image of images; track $index) {
      <igx-slide>
        <div class="image_container">
          <img class="dialog_carousel_image" [src]="image.dataUrl" [alt]="image.title">
        </div>
      </igx-slide>
      }
      } @else {
      <div class="image_container">
        <img class="dialog_placeholder_image" src="../../../../assets/images/placeholder.png" alt="placeholder">
      </div>
      }
    </igx-carousel>
    <!-- <p class="image_decription">{{carousel.current ? images[carousel.current].description ?? "Ingen beskrivelse
      tilgjengelig.": ""}}</p> -->
  </div>
  <!-- } -->
</igx-dialog>