@if (sys.userRights) {

@if(isDesktop){
<div class="button-row">
  <div class="button-cluster">


    <label [ngClass]="rolePermissionsLevel > 0 ? 'docRow-btn' : 'docRow-btn-inactive'" class="auto-btn"
      (click)="rolePermissionsLevel > 0 ? roleFormDialog.open() : null; caseContactDialogTitle = 'Ny Rolle'; toAddNew(); clearSelection(); resetState();">
      <span translate="no" class="spacer material-symbols-outlined">person_add</span>
      <span>Ny rolle</span>
    </label>

    <label [ngClass]="selectedCaseContact && rolePermissionsLevel > 1 ? 'docRow-btn' : 'docRow-btn-inactive'"
      class="auto-btn" (click)="rolePermissionsLevel > 1 ? removeCaseContact() : null">
      <span translate="no" class="spacer material-symbols-outlined">person_remove</span>
      <span>Slett rolle</span>
    </label>

    <label [ngClass]="selectedCaseContact && rolePermissionsLevel > 1 ? 'docRow-btn' : 'docRow-btn-inactive'"
      class="auto-btn"
      (click)="rolePermissionsLevel > 1 ? roleFormDialog.open() : null; toEdit(); caseContactDialogTitle = 'Rediger Rolle'">
      <span translate="no" class="spacer material-symbols-outlined">manage_accounts</span>
      <span class="edit-btn">Rediger rolle </span>
    </label>

    <label [ngClass]="selectedCaseContact && rolePermissionsLevel > 0 ? 'docRow-btn' : 'docRow-btn-inactive'"
      class="auto-btn" (click)="onContactOpen()">
      <span translate="no" class="spacer material-symbols-outlined">open_in_new</span>
      <span class="edit-btn">Åpne kontakt </span>
    </label>

    <label [ngClass]="selectedCaseContact ? 'docRow-btn' : 'docRow-btn-inactive'" class="auto-btn"
      (click)="clearSelection(); resetState();">
      <span translate="no" class="spacer material-symbols-outlined">remove_selection</span>
      <span>Nullstill valg</span>
    </label>
  </div>
  <div class="button-cluster">
    <app-button-row-button dlxExcelHandler [grid]="caseRoleGrid" fileName="SakRoller" label="Excel" icon="table_view"
      [enabled]="!!caseRoleDs.length" />
  </div>
</div>
} @else {
<div class="button-row">
  <app-button-row-button label="Handlinger" icon="arrow_drop_down" [igxToggleAction]="CaseContactActionDropdown"
    [igxDropDownItemNavigation]="CaseContactActionDropdown" [igxToggleOutlet]="openOutlet">
  </app-button-row-button>
  <div class="overlayOutlet" igxOverlayOutlet #openOutlet="overlay-outlet"></div>
</div>
}


<igx-drop-down #CaseContactActionDropdown>
  <div class="card-wrapper">
    <igx-card elevated>
      <igx-card-content>
        <div class="flex-col">
          <button
            [ngClass]="rolePermissionsLevel > 0 ? 'dropdown-action-menu-button' : 'dropdown-action-menu-button--inactive'"
            (click)="rolePermissionsLevel > 0 ? roleFormDialog.open() : null; caseContactDialogTitle = 'Ny Rolle'; toAddNew();
            clearSelection(); resetState();">
            <span translate="no" class="material-symbols-outlined icon">person_add</span>
            <span>Ny rolle</span>
          </button>
          <button
            [ngClass]="selectedCaseContact && rolePermissionsLevel > 1 ? 'dropdown-action-menu-button' : 'dropdown-action-menu-button--inactive'"
            (click)="rolePermissionsLevel > 1 ? removeCaseContact() : null">
            <span translate="no" class="material-symbols-outlined icon">person_remove</span>
            <span>Slett rolle</span>
          </button>
          <button
            [ngClass]="selectedCaseContact && rolePermissionsLevel > 1? 'dropdown-action-menu-button' : 'dropdown-action-menu-button--inactive'"
            (click)="rolePermissionsLevel > 1 ? roleFormDialog.open() : null; toEdit(); caseContactDialogTitle = 'Rediger Rolle'">
            <span translate="no" class="material-symbols-outlined icon">manage_accounts</span>
            <span>Rediger rolle</span>
          </button>
          <button
            [ngClass]="selectedCaseContact && rolePermissionsLevel > 0 ? 'dropdown-action-menu-button' : 'dropdown-action-menu-button--inactive'"
            (click)="onContactOpen()">
            <span translate="no" class="material-symbols-outlined icon">open_in_new</span>
            <span>Åpne kontakt</span>
          </button>
          <button
            [ngClass]="selectedCaseContact ? 'dropdown-action-menu-button' : 'dropdown-action-menu-button--inactive'"
            (click)="clearSelection(); resetState();">
            <span translate="no" class="material-symbols-outlined icon">remove_selection</span>
            <span>Nullstill valg</span>
          </button>
          <button
            [ngClass]="!!caseRoleDs.length ? 'dropdown-action-menu-button' : 'dropdown-action-menu-button--inactive'">
            <span translate="no" class="material-symbols-outlined icon">table_view</span>
            <span>Excel</span>
          </button>
        </div>
      </igx-card-content>
    </igx-card>
  </div>
</igx-drop-down>


<igx-dialog #roleFormDialog [closeOnOutsideSelect]="false" leftTopLabel="OK"
  (leftBottomSelect)="roleFormDialog.close(); clearSelection(); resetState();">
  <igx-dialog-title>
    <div class="dialog-title-container">
      <div class="dialog-title">{{caseContactDialogTitle}}</div>
      <i translate="no" class="material-symbols-outlined"
        (click)="roleFormDialog.close(); clearSelection(); resetState();">close</i>
    </div>
  </igx-dialog-title>

  <form [formGroup]="roleForm" (ngSubmit)="onFormSubmit()" class="flex-col role-form">
    <div class="simple-select-wrapper ">
      <label igxLabel class="simple-select-label simple-select-label--selected">Rollekategori:</label>
      <igx-simple-combo class="simple-select-disable-reset" formControlName="roleCategoryId" [type]="'border'"
        (selectionChanging)="onRoleCategoryChanging($event.newSelection.Id); combo.searchTypeChanging($event);"
        [data]="roleTypeCategories" [displayKey]="'Name'" [valueKey]="'Id'" placeholder="* Velg Rollekategori"
        [style.--ig-size]="'var(--ig-size-small)'">
      </igx-simple-combo>
    </div>

    <div class="simple-select-wrapper" *ngIf="activeRoleTypes">
      <label igxLabel class="simple-select-label simple-select-label--selected">Rolle:</label>
      <igx-simple-combo class="simple-select-disable-reset rolle-types" formControlName="roleTypeId" [type]="'border'"
        (selectionChanging)="onRoleTypeChanging($event); combo.searchTypeChanging($event);" [data]="activeRoleTypes"
        [displayKey]="'Name'" [valueKey]="'Id'" placeholder="* Velg rolle" [style.--ig-size]="'var(--ig-size-small)'">
        <ng-template igxComboItem let-item let-key="valueKey">
          <div [class.disabled-item]="isItemDisabled(item.Id)">
            {{ item.Name }}
          </div>
        </ng-template>
      </igx-simple-combo>
      <div class="exisiting-role" class="existing-role" [class.visible]="showExistingWarning">
        <p>*Saken har allerede {{existingWarningProp}}</p>
      </div>
    </div>

    <igx-input-group class="flex-row  contact-search-input" type="border" [style.--ig-size]="'var(--ig-size-small)'">
      <input igxInput formControlName="contactName" name="contactName" (keyup.enter)="lookupContact()" />
      <label igxLabel for="contactName">Kontakt:</label>
      <igx-icon igxSuffix (click)="clearSearch()">clear</igx-icon>
      <igx-icon igxSuffix (click)="lookupContact()">search</igx-icon>
    </igx-input-group>

    <div class="flex-row btn-section">
      <button type="submit" igxButton="contained" [style.color]="'white'"
        [style.background]=" submitEnabled() ? '#AEC965' : '#E0E0E0'" igxRipple="white" [disabled]="!submitEnabled()">
        LEGG TIL
      </button>

      <button igxButton="contained" [style.color]="'white'" [style.background]="'#575757'" igxRipple="white"
        type="button" (click)="roleFormDialog.close(); clearSelection(); resetState() ">
        AVBRYT
      </button>
    </div>
  </form>
</igx-dialog>



<igx-dialog #contactSearchDialog [closeOnOutsideSelect]="false" leftTopLabel="OK"
  (closed)="onSearchDialogClosed() ; resetContactDialog()">

  <igx-dialog-title>
    <div class="dialog-title-container">
      <div class="dialog-title">Kontaktsøk</div>
      <i translate="no" class="material-symbols-outlined"
        (click)="contactSearchDialog.close(); resetContactDialog()">close</i>
    </div>
  </igx-dialog-title>

  <app-contacts [ngStyle]="{'height': '70vh', 'width': '70vw'}" *ngIf="showDialog && queryParams"
    [dialogContactsSource]="dialogContactsSource" [contactSearchOrigin]="contactSearchOrigin"
    [sentSearchInput]="contactSearchInput" (sendFoundContact)="resultFromDialgSearch($event)"
    [queryParams]="queryParams" [roleContactSector]="contactSectorId"></app-contacts>
</igx-dialog>


<igx-grid igxPreventDocumentScroll #caseRoleGrid id="case-role-grid" class="remove-shadows" [data]="caseRoleDs"
  [autoGenerate]="false" width="100%" [cellSelection]="'none'" [rowSelection]="'single'"
  [style.--ig-size]="'var(--ig-size-small)'" (rowSelectionChanging)="handleRowSelection($event.newSelection[0])"
  (doubleClick)="onContactOpen()" [hideRowSelectors]="true" sortable="true" [height]="'100%'"
  [allowFiltering]="isMobile ? false : true" emptyFilteredGridMessage="Filtrering gir ingen treff">
  @if (!isMobile) {
  <igx-column field="ContactNumber" [width]="isDesktop ? '90px': '75px'" header="K.nr" [sortable]="true"
    [dataType]="'string'" [filterCellTemplate]="caseRoleFilterTemplate" />
  }
  <igx-column field="RoleTypeCode" [width]="isDesktop ? '90px': '60px'" header="Rolle" [sortable]="true"
    [dataType]="'string'" [filterCellTemplate]="caseRoleFilterTemplate" />

  @if (!isMobile) {
  <igx-column field="RoleTypeName" header="Rollenavn" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="caseRoleFilterTemplate" />
  }

  <igx-column field="ContactName" header="Navn" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="caseRoleFilterTemplate" />

  @if (!isMobile) {
  <ng-container>
    <igx-column field="Address" header="Adresse" [sortable]="true" [dataType]="'string'"
      [filterCellTemplate]="caseRoleFilterTemplate" />
    <igx-column field="AllPhoneNumbers" header="Telefon" [sortable]="true" [dataType]="'string'"
      [filterCellTemplate]="caseRoleFilterTemplate" />
    <igx-column field="Email" header="E-post" [sortable]="true" [filterCellTemplate]="caseRoleFilterTemplate" />
  </ng-container>

  }

  @if(isMobile) {
  <igx-column width="180px" field="Address" header="K.info" [filterCellTemplate]="caseRoleFilterTemplate">
    <ng-template #adressTemp igxCell let-cell="cell">
      <div class="contact-details">
        <span translate="no"
          [ngClass]="cell.row.data.Address ? 'active-icon material-symbols-outlined' : 'material-symbols-outlined inactive-icon'"
          (click)="cell.row.data.Address ? onGPSClick($event, cell.row.data.Address) : null">
          home_pin
        </span>
        <span translate="no"
          [ngClass]="cell.row.data.AllPhoneNumbers ? 'active-icon material-symbols-outlined' : 'material-symbols-outlined inactive-icon'"
          (click)="cell.row.data.AllPhoneNumbers ? onTelClick($event, cell.row.data.AllPhoneNumbers) : null">
          phone
        </span>
        <span translate="no"
          [ngClass]="cell.row.data.Email ? 'active-icon material-symbols-outlined' : 'material-symbols-outlined inactive-icon'"
          (click)="cell.row.data.Email ? onMailClick($event, cell.row.data.Email) : null">
          mail
        </span>
      </div>
    </ng-template>
  </igx-column>
  }

  @if(isDesktop) {
  <ng-container>
    <igx-column field="Digipost" width="80px" header="Digi" [sortable]="true" [dataType]="'boolean'"
      [filterCellTemplate]="caseRoleFilterTemplate" />
    <igx-column field="IsShortlisted" width="80px" header="Min sak" [sortable]="true" [dataType]="'boolean'"
      [filterCellTemplate]="caseRoleFilterTemplate">
      <ng-template igxHeader let-column>
        <div style="line-height: 0px; margin-bottom: 5px;">
          <i translate="no" class="material-symbols-outlined">star</i>
        </div>
      </ng-template>
    </igx-column>
  </ng-container>

  }

  <igx-grid-footer class="grid-footer-container">
    <dlx-grid-item-count [isMobile]="isMobile" [current]="caseRoleGrid.totalRowsCountAfterFilter"
      [total]="caseRoleDs.length" />
  </igx-grid-footer>
</igx-grid>

<ng-template #caseRoleFilterTemplate igxFilterCellTemplate let-column="column">
  <grid-filter-input [grid]="caseRoleGrid" [column]="column"></grid-filter-input>
</ng-template>

}