export enum MimeType {
    // Text
    TEXT_PLAIN = "text/plain",
    TEXT_HTML = "text/html",
    TEXT_CSS = "text/css",
    TEXT_JAVASCRIPT = "text/javascript",

    // Images
    IMAGE_JPEG = "image/jpeg",
    IMAGE_PNG = "image/png",
    IMAGE_GIF = "image/gif",
    IMAGE_SVG_XML = "image/svg+xml",
    IMAGE_WEBP = "image/webp",

    // Application Files
    APPLICATION_PDF = "application/pdf",
    APPLICATION_JSON = "application/json",
    APPLICATION_XML = "application/xml",
    APPLICATION_ZIP = "application/zip",
    
    // Microsoft Office Files
    APPLICATION_MSWORD = "application/msword", // .doc
    APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT = "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx

    APPLICATION_VND_MS_EXCEL = "application/vnd.ms-excel", // .xls
    APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx

    APPLICATION_VND_MS_POWERPOINT = "application/vnd.ms-powerpoint", // .ppt
    APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION = "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx

    // Audio/Video
    AUDIO_MPEG = "audio/mpeg",
    AUDIO_OGG = "audio/ogg",
    VIDEO_MP4 = "video/mp4",
    VIDEO_WEBM = "video/webm",

    // Binary Data
    APPLICATION_OCTET_STREAM = "application/octet-stream"
}

function getFileExtension(filename: string): string {
    const dotIndex = filename.lastIndexOf('.');
    if (dotIndex === -1 || dotIndex === 0) {
        // No extension found or filename starts with a dot (e.g., ".hiddenfile")
        return '';
    }
    return filename.slice(dotIndex + 1).toLowerCase(); // Return extension in lowercase
}
export function getMimeTypeFromFilename(filename: string): MimeType {
    
    switch (getFileExtension(filename).toLowerCase()) {
        case 'txt':
            return MimeType.TEXT_PLAIN;
        case 'html':
        case 'htm':
            return MimeType.TEXT_HTML;
        case 'css':
            return MimeType.TEXT_CSS;
        case 'js':
            return MimeType.TEXT_JAVASCRIPT;
        case 'jpg':
        case 'jpeg':
            return MimeType.IMAGE_JPEG;
        case 'png':
            return MimeType.IMAGE_PNG;
        case 'gif':
            return MimeType.IMAGE_GIF;
        case 'svg':
            return MimeType.IMAGE_SVG_XML;
        case 'webp':
            return MimeType.IMAGE_WEBP;
        case 'pdf':
            return MimeType.APPLICATION_PDF;
        case 'json':
            return MimeType.APPLICATION_JSON;
        case 'xml':
            return MimeType.APPLICATION_XML;
        case 'zip':
            return MimeType.APPLICATION_ZIP;
        case 'doc':
            return MimeType.APPLICATION_MSWORD;
        case 'docx':
            return MimeType.APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT;
        case 'xls':
            return MimeType.APPLICATION_VND_MS_EXCEL;
        case 'xlsx':
            return MimeType.APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET;
        case 'ppt':
            return MimeType.APPLICATION_VND_MS_POWERPOINT;
        case 'pptx':
            return MimeType.APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION;
        case 'mp3':
            return MimeType.AUDIO_MPEG;
        case 'ogg':
            return MimeType.AUDIO_OGG;
        case 'mp4':
            return MimeType.VIDEO_MP4;
        case 'webm':
            return MimeType.VIDEO_WEBM;
        default:
            return MimeType.APPLICATION_OCTET_STREAM; // Default for unknown types
    }
}

