import { Component, ComponentRef, ElementRef, Input, Output, OnInit, ViewChild, ViewContainerRef, EventEmitter } from '@angular/core';
import { IgxColumnComponent, GridColumnDataType, IgxNumberFilteringOperand, IgxStringFilteringOperand, IgxDateFilteringOperand, IgxGridComponent, RowType, SortingDirection, IgxHintDirective, IgxInputGroupComponent, IgxInputDirective, IgxLabelDirective, IgxSimpleComboComponent, IgxFilterCellTemplateDirective, IgxButtonDirective, IgxRippleDirective, IgxCircularProgressBarComponent, ISimpleComboSelectionChangingEventArgs } from '@infragistics/igniteui-angular';
import { DocumentCheckoutService } from 'src/app/components/UI/document-checkout/document-checkout.service';
import { DocumentFromTemplateModalComponent } from 'src/app/components/UI/modal-hub/modals/document-from-template-modal/document-from-template-modal.component';
import { DatalexClient, IDocumentAddresseeBE, ICaseBE, ICaseContactBE, IDocumentCategoryBE, IDocumentSubCategoryBE, IDocumentTemplateBE, IUserLimitedBE } from '@datalex-software-as/datalex-client';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { ViewingDate, getJsonDateWithTimeZoneOffset } from 'src/app/util/DatalexDateTime';
import { DocumentAddresseeType } from 'src/app/util/DocumentAddresseeTypeEnum';
import { GridMethodsService } from 'src/app/services/grid-methods.service';
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import { DatalexMsalService } from 'src/app/services/datalex-msal.service';
import { Router } from '@angular/router';
import { EventEmitterService, IForcedEventTypeEnum } from 'src/app/services/event-emitter.service';
import { IDocumentLimitedGrid } from '../document-grid/document-grid.component';
import { fileIcon } from 'src/app/util/FileIcons';
import { DateDisplay } from 'src/app/util/DateDisplay';
import { DeviceService } from 'src/app/services/device.service';
import { GridFilterInputComponent } from '../../../../UI/grid-filter-input/grid-filter-input.component';
import { ButtonRowButtonComponent } from '../../../../UI/button-row-button/button-row-button.component';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HeadingComponent } from '../../../../UI/heading/heading.component';
import { DocumentUrlService } from 'src/app/services/document-url.service';


export interface DocumentRecipient extends ICaseContactBE {
  $Type?: string;
  $TypeId?: string;
}

export interface DocumentFromTemplateInformation {
  title: string;
  author: string,
  date: string,
  description: string | null,
  category: string | null,
  subCategory: string | null
}

@Component({
  selector: 'app-document-from-template',
  templateUrl: './document-from-template.component.html',
  styleUrls: ['./document-from-template.component.scss'],
  standalone: true,
  imports: [HeadingComponent, IgxGridComponent, IgxColumnComponent, IgxHintDirective, IgxInputGroupComponent, FormsModule, IgxInputDirective, IgxLabelDirective, IgxSimpleComboComponent, NgIf, ButtonRowButtonComponent, IgxFilterCellTemplateDirective, GridFilterInputComponent, IgxButtonDirective, IgxRippleDirective, IgxCircularProgressBarComponent]
})

export class DocumentFromTemplateComponent implements OnInit {

  deviceType: string;

  constructor(
    private dlxClient: DatalexClient,
    private dlxMsal: DatalexMsalService,
    private router: Router,
    private sys: SystemCacheService,
    private dcs: DocumentCheckoutService,
    public gms: GridMethodsService,
    public combo: ComboSelectFunctionsService,
    public elRef: ElementRef,
    private ev: EventEmitterService,
    public deviceService: DeviceService,
    private docUrl: DocumentUrlService
  ) {
    this.deviceType = this.deviceService.getDeviceType();
  }

  @Input() case!: ICaseBE;
  @Input() parent!: ComponentRef<DocumentFromTemplateModalComponent>;

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  @ViewChild('docTemplateGrid', { read: IgxGridComponent, static: false }) public templateGrid!: IgxGridComponent;
  @ViewChild('caseRoleGrid', { read: IgxGridComponent, static: false }) public roleGrid!: IgxGridComponent;

  @ViewChild('subCat', { read: ViewContainerRef }) public subCatRef!: ViewContainerRef;

  documentTemplates!: IDocumentTemplateBE[];
  documentRecipients!: DocumentRecipient[];
  selectedTemplate!: IDocumentTemplateBE;
  selectedRecipient!: DocumentRecipient;

  hasAddressee: boolean | null = false;
  hasAttention: boolean | null = false;
  hasCopy: boolean | null = false;

  users: IUserLimitedBE[] = this.sys.allEmployees;
  documentCategories: IDocumentCategoryBE[] = this.sys.documentCategories;
  documentSubCategories: IDocumentSubCategoryBE[] = [];

  addressee!: IDocumentAddresseeBE | null;
  attention!: IDocumentAddresseeBE | null;
  copy!: IDocumentAddresseeBE | null;
  authorAdresse!: IDocumentAddresseeBE | null;

  documentAddressees: IDocumentAddresseeBE[] = [];

  title!: string;
  author!: string;
  date!: string;
  description!: string;
  categoryId!: string;
  subCategoryId!: string | null;

  subCategoriesReadOnly: boolean = true;

  canOpenDocument: boolean = false;

  templatesLoading = true;
  caseContactsLoading = true;
  documentLoading = false;

  styles = {
    color: (row: RowType) => this.isOpenXML(row.data.File.Extension) ? '#000' : '#BCBCBC',
    pointerEvents: (row: RowType) => this.isOpenXML(row.data.File.Extension) ? 'initial' : 'none'
  }

  getJsonDateWithTimeZoneOffset = getJsonDateWithTimeZoneOffset
  viewDate!: string

  ngOnInit() {
    // this.date = getJsonDateWithTimeZoneOffset()//.toJSON();

    this.init();
  }

  init(): void {

    this.caseContactsLoading = true;
    this.dlxClient.GetCaseContactsByCaseId(this.case.Id).subscribe({
      next: (contacts) => {
        this.documentRecipients = contacts
        this.caseContactsLoading = false;
        this.roleGrid.sort({ fieldName: 'RoleTypeName', dir: SortingDirection.Asc })
      }
    })
    this.templatesLoading = true;
    this.dlxClient.GetDocumentTemplates().subscribe({
      next: (templates) => {
        //let arr1 = templates.filter(template => this.isOpenXML(template.File.Extension));
        //let arr2 = templates.filter(template => !this.isOpenXML(template.File.Extension));

        this.documentTemplates = templates//arr1.concat(arr2);
        this.templatesLoading = false;
        this.templateGrid.rowStyles = this.styles;
        this.templateGrid.sort({ fieldName: 'Name', dir: SortingDirection.Asc, ignoreCase: true })
      }
    })


    this.title = this.case.Title;
    this.author = this.sys.loggedinUserContact.Id;
    this.authorAdresse = {
      AddresseeType: DocumentAddresseeType.Author,
      ContactId: this.sys.loggedinUserContact.Id,
      ContactSectorId: this.sys.loggedinUserContact.ContactSectorId as string,
      IsNew: false,
      IsDeleted: false
    }

    // this.date = new Date().toISOString().substring(0, 10);
    this.date = getJsonDateWithTimeZoneOffset();
    const _D = ViewingDate(new Date());
    this.viewDate = _D.date + " " + _D.time;
    this.description = "";
    this.categoryId = "";
    this.subCategoryId = "";
  }

  templateSelected(event: any) {

    this.selectedTemplate = event.added[0];
    this.hasAddressee = this.selectedTemplate.HasAddressee;
    this.hasAttention = this.selectedTemplate.HasAttention;
    this.hasCopy = this.selectedTemplate.HasCopy;

    this.canOpenDocument = this.setCanOpen();
  }

  recipientSelected(event: any) {
    this.selectedRecipient = event.added[0];
  }

  isOpenXML(extension: string): boolean {
    switch (extension.toLowerCase()) {
      case ".docx":
      case ".dotx":
      case ".docm":
      case ".dotm":
        return true
      default:
        return false;
    }
  }

  GetExtension(extension: string): string {
    if (extension == ".dotx")
      return ".docx";

    return extension;
  }


  setAddressee(type: string) {
    const existingIndex = this.documentRecipients
      .map(recipients => recipients.$Type)
      .indexOf(type);

    if (existingIndex !== -1) {
      this.documentRecipients[existingIndex].$Type = ""
    }

    const index = this.documentRecipients
      .map(recipients => recipients.Id)
      .indexOf(this.selectedRecipient.Id);
    this.documentRecipients[index].$Type = type;

    switch (type) {
      case "Hovedadressat":
        this.addressee = {
          AddresseeType: DocumentAddresseeType.MainAddressee,
          ContactId: this.documentRecipients[index].ContactId,
          ContactSectorId: this.documentRecipients[index].ContactSectorId as string,
          IsNew: false,
          IsDeleted: false
        }

        break
      case "Attention":
        this.attention = {
          AddresseeType: DocumentAddresseeType.Attention,
          ContactId: this.documentRecipients[index].ContactId,
          ContactSectorId: this.documentRecipients[index].ContactSectorId as string,
          IsNew: false,
          IsDeleted: false
        }
        break
      case "Kopi":
        this.copy = {
          AddresseeType: DocumentAddresseeType.CopyAddressee,
          ContactId: this.documentRecipients[index].ContactId,
          ContactSectorId: this.documentRecipients[index].ContactSectorId as string,
          IsNew: true,
          IsDeleted: false
        }
        break
      default:
        break
    }
    this.roleGrid.refreshGridState();
    this.canOpenDocument = this.setCanOpen();
  }

  resetAdressees() {
    for (let index = 0; index < this.documentRecipients.length; index++) {
      this.documentRecipients[index].$Type = "";
    }
    this.addressee = null;
    this.attention = null;
    this.copy = null;
    this.roleGrid.refreshGridState();
    this.canOpenDocument = this.setCanOpen();
  }

  onAuthorChange(event: any) {
    if (!event.newSelection || event.newSelection.Id === this.author) {
      event.cancel = true;
      return;
    }

    this.author = event.newSelection ? event.newSelection.Id : this.author;
    this.dlxClient.GetContactById(this.author).subscribe({
      next: (contact) => {
        this.authorAdresse = {
          AddresseeType: DocumentAddresseeType.Author,
          ContactId: contact.Id,
          ContactSectorId: contact.ContactSectorId as string,
          IsNew: false,
          IsDeleted: false
        }
      }
    })

    this.canOpenDocument = this.setCanOpen();

  }

  authorReset() {
    this.authorAdresse = null;
    this.canOpenDocument = this.setCanOpen();
  }

  setCanOpen(): boolean {
    if (!this.selectedTemplate) return false;
    if (!this.authorAdresse) return false;
    if (this.selectedTemplate.NeedsAddressee) {
      if (!this.addressee) return false;
    }
    if (this.selectedTemplate.NeedsAttention) {
      if (!this.attention) return false;
    }
    return true
  }

  categoryChange(event: any) {

    if (!event.newSelection && event.oldSelection?.Id === this.categoryId) {
      event.cancel = true;
      return;
    }

    this.categoryId = event.newSelection ? event.newSelection.Id : this.categoryId;
    this.dlxClient.GetDocumentSubCategorysByCategory(this.categoryId).subscribe({
      next: (subCategories: IDocumentSubCategoryBE[]) => {
        this.documentSubCategories = subCategories;
        this.subCategoryId = "";

      }
    });
  }

  subCategoryChange(event: any) {

    if (!event.newSelection && event.oldSelection?.Id === this.subCategoryId) {
      event.cancel = true;
      return;
    }
    this.subCategoryId = event.newSelection ? event.newSelection.Id : this.subCategoryId;
  }

  categoryReset() {
    this.subCategoryId = "";
  }

  openDocument() {
    this.documentLoading = true;
    this.dlxClient.CreateCaseDocumentFromTemplate(
      this.selectedTemplate.Id,
      this.case.Id,
      this.selectedTemplate.DocumentTypeId,
      [this.addressee ?? null, this.attention ?? null, this.copy ?? null, this.authorAdresse].filter((item: IDocumentAddresseeBE | null) => item !== null) as IDocumentAddresseeBE[],
      this.case.AccountId,
      this.date,
      this.categoryId,
      this.subCategoryId,
      this.title,
      this.description,
      this.title + this.GetExtension(this.selectedTemplate.File.Extension)).subscribe({
        next: (doc) => {
          this.dlxMsal.useToken(this.router.url).subscribe({
            next: (token) => {
              this.dlxClient.UploadDocumentSharePoint(doc.Id, true, false, token).subscribe({
                next: (response) => {
                  window.open(response.WebUrl, '_blank');
                  this.dcs.getCheckedOutDocuments(this.sys.loggedInUser.Id)
                  this.dlxClient.GetDocumentLimited(doc.Id).subscribe({
                    next: (res) => {


                      let gridRow: IDocumentLimitedGrid = (res as IDocumentLimitedGrid);
                      let tempFile = gridRow.Filename ? gridRow.Filename!.split('.') : [""];
                      gridRow.Icon = fileIcon(tempFile[tempFile.length - 1]);
                      gridRow.ChangedDate = DateDisplay(gridRow.DateChanged!);
                      gridRow.DocNr = String(gridRow.DocumentNr);

                      gridRow.WebUrl = this.docUrl.getAccessOrientetUrl(response.WebUrl);
                      this.documentLoading = false;
                      this.onClose.emit();
                      this.ev.sendForceUpdate({ type: IForcedEventTypeEnum.DocumentsUpdated, document: gridRow, rowId: null })
                    }
                  })
                }
              })
            }
          })

        }
      })

    // this.onClose.emit();
  }

  private _filterValues = new Map<IgxColumnComponent, any>();
  //GRID FUNCTIONS


  onRightClick(event: any) {
    event.preventDefault();
  }
  public formatDate(val: Date) {
    return new Intl.DateTimeFormat('en-US').format(val);
  }

  public formatCurrency(val: string) {
    return parseInt(val, 10).toFixed(2);
  }

  public getFilterValue(column: IgxColumnComponent): any {
    return this._filterValues.has(column) ? this._filterValues.get(column) : null;
  }

  public onKeyDown(event: KeyboardEvent) {
    event.stopImmediatePropagation();
  }

  public onInput(grid: IgxGridComponent, input: any, column: IgxColumnComponent) {
    this._filterValues.set(column, input.value);

    if (input.value === '') {
      grid.clearFilter(column.field);
      return;
    }

    let operand = null;
    switch (column.dataType) {
      case GridColumnDataType.Number:
        operand = IgxNumberFilteringOperand.instance().condition('equals');
        break;
      default:
        operand = IgxStringFilteringOperand.instance().condition('contains');
    }
    grid.filter(column.field, this.transformValue(input.value, column), operand, column.filteringIgnoreCase);
  }

  public clearInput(column: IgxColumnComponent) {
    this._filterValues.delete(column);
    this.roleGrid.clearFilter(column.field);
  }

  public onClick(event: any) {
    const { target } = event;
    if (!target.isFocused) {
      target.focus();

    }
  }

  public onDateSelected(event: any, column: IgxColumnComponent) {
    this._filterValues.set(column, event);

    this.roleGrid.filter(column.field, event, IgxDateFilteringOperand.instance().condition('equals'),
      column.filteringIgnoreCase);
  }

  public openDatePicker(openDialog: () => void) {
    openDialog();
  }

  private transformValue(value: any, column: IgxColumnComponent): any {
    if (column.dataType === GridColumnDataType.Number) {
      value = parseFloat(value);
    }

    return value;
  }

  onComboSelectionChanging(event: ISimpleComboSelectionChangingEventArgs) {
    if (event.newSelection && event.newSelection === this.author) {
      event.cancel = true;
    }
  }

}
