<!-- <div class="editCaseCategoryPanel">
  <button igxButton="raised" width="100%"  [style.color]="'white'" [style.background]="'#575757'" igxRipple="white"
    (click)="editCaseCategories = !editCaseCategories">{{!editCaseCategories ? "Editer" : "Tilbake"}}</button>
</div> -->

<div class="upper-content-wrapper">
  <ng-content *ngIf="editCaseCategories; then editCategorys; else categorys;"></ng-content>
</div>
<div class="lower-content-wrapper">
  <!-- <button igxButton="raised"  [style.color]="'white'" [style.background]="'#575757'" igxRipple="white"
    (click)="save()">Lagre</button> -->


  <button [igxButton]="btnEnabled ? 'contained' : ''" [style.color]="'white'"
    [style.background]="btnEnabled ? '#AEC965': '#E0E0E0'" [ngStyle]="{'cursor': btnEnabled ? 'pointer' : 'auto'}"
    igxRipple="white" (click)="save()">Lagre</button>

  <button igxButton="contained" [style.color]="'white'" [style.background]="'#575757'" igxRipple="white"
    (click)="cancle()">Lukk</button>
</div>

<ng-template #categorys>
  <div class="grid_wrapper 0_wrapper">
    <!-- <igx-grid igxPreventDocumentScroll #grid_0 [data]="data_0 | async" -->
    <igx-grid igxPreventDocumentScroll #grid_0 [data]="categories" class="category-grid" [autoGenerate]="false"
      [emptyGridMessage]="grid_0_empty" (rowSelectionChanging)="onGrid0RowSelecetion($event)" [cellSelection]="'none'"
      [rowSelection]="'single'" [hideRowSelectors]="true" sortable="true" [allowFiltering]="true"
      [style.--ig-size]="'var(--ig-size-small)'">
      <igx-column width="100%" field="Name" header="Kategori" [sortable]="true" [dataType]="'string'"
        [filterCellTemplate]="default0FilterTemplate"></igx-column>
    </igx-grid>
  </div>
  <div class="grid_wrapper 1_wrapper">
    <igx-grid igxPreventDocumentScroll #grid_1 [data]="subCategories" class="category-grid" [autoGenerate]="false"
      [emptyGridMessage]="grid_1_empty" (rowSelectionChanging)="onGrid1RowSelecetion($event)" [cellSelection]="'none'"
      [rowSelection]="'single'" [hideRowSelectors]="true" sortable="true" [allowFiltering]="true"
      [style.--ig-size]="'var(--ig-size-small)'">
      <igx-column width="100%" field="Name" header="Underkategori" [sortable]="true" [dataType]="'string'"
        [filterCellTemplate]="default1FilterTemplate"></igx-column>
    </igx-grid>
  </div>
  <ng-template #default0FilterTemplate igxFilterCellTemplate let-column="column">
    <grid-filter-input [grid]="grid_0" [column]="column"></grid-filter-input>
  </ng-template>

  <ng-template #default1FilterTemplate igxFilterCellTemplate let-column="column">
    <grid-filter-input [grid]="grid_1" [column]="column"></grid-filter-input>
  </ng-template>
</ng-template>

<ng-template #editCategorys>
  <div class="grid_group">
    <div class="btn_row">
      <span (click)="createNewCaseCategory(edit_grid_0)" class="btn_enabled">Ny</span>
      <span (click)="deleteCategory(edit_grid_0)"
        [ngClass]="[selectedCategory ? 'btn_enabled' : 'btn_disabled']">Slett</span>
    </div>
    <igx-grid igxPreventDocumentScroll #edit_grid_0 [data]="edit_data_0" class="category-grid" [autoGenerate]="false"
      [emptyGridMessage]="editGrid_0_empty" (rowSelectionChanging)="onEditGrid0RowSelecetion($event, edit_grid_0)"
      [cellSelection]="'single'" primaryKey="Id" [rowSelection]="'single'" (cellEditDone)="onCellEditExit0($event)"
      [hideRowSelectors]="true" [style.--ig-size]="'var(--ig-size-small)'">
      <igx-column width="100%" field="Name" header="Kategori" [sortable]="true" [dataType]="'string'" [editable]="true">
        <ng-template igxCellEditor let-cell="cell" let-value>
          <div class="cell-editor-wrapper">
            <input type="text" id="catInput" [(ngModel)]="cell.editValue" />
            <div class="clickableIconTray">
              <span class="material-icons" (click)="categoryEditComplete(cell)">check</span>
              <span class="material-icons" (click)="cancleNew(cell)">close</span>
            </div>
          </div>
        </ng-template>
      </igx-column>
    </igx-grid>
  </div>
  <div class="grid_group">
    <div class="btn_row">
      <span (click)="createNewCaseSubCategory(edit_grid_1)"
        [ngClass]="[selectedCategory ? 'btn_enabled' : 'btn_disabled']">Ny</span>
      <span (click)="deleteSubCategory(edit_grid_1)"
        [ngClass]="[selectedSubCategory ? 'btn_enabled' : 'btn_disabled']">Slett</span>
    </div>
    <igx-grid igxPreventDocumentScroll #edit_grid_1 [data]="edit_data_1" class="category-grid" [autoGenerate]="false"
      [emptyGridMessage]="editGrid_1_empty" (cellEditDone)="onCellEditExit1($event)"
      (rowSelectionChanging)="onEditGrid1RowSelecetion($event)" [cellSelection]="'single'" [rowSelection]="'single'"
      primaryKey="Id" [hideRowSelectors]="true" [style.--ig-size]="'var(--ig-size-small)'">
      <igx-column width="100%" field="Name" header="Underkategori" [sortable]="true" [dataType]="'string'"
        [editable]="true">
        <ng-template igxCellEditor let-cell="cell" let-value>
          <div class="cell-editor-wrapper">
            <input type="text" id="subCatInput" (focus)="log('focus')" [(ngModel)]="cell.editValue" />
            <div class="clickableIconTray">
              <span class="material-icons" (click)="subCategoryEditComplete(cell)">check</span>
              <span class="material-icons" (click)="cancleNew(cell)">close</span>
            </div>
          </div>
        </ng-template>
      </igx-column>
    </igx-grid>
  </div>
</ng-template>