import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DatalexClient, IDocumentBE, IDocumentLimitedBE, IDocumentPack, IDocumentSharePointBE, IDocumentSubCategoryBE, IUserLimitedBE } from '@datalex-software-as/datalex-client';
import { ToggleSwitchComponent } from '../controls/toggle-switch/toggle-switch.component';
import { FormsModule } from '@angular/forms';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { DocumentTypeEnum } from 'src/app/util/DocumentTypesUtil';
import { IComboSelectionChangingEventArgs, IGridCellEventArgs, IgxButtonGroupModule, IgxButtonModule, IgxCellHeaderTemplateDirective, IgxCellTemplateDirective, IgxColumnComponent, IgxComboComponent, IgxDatePickerModule, IgxDialogComponent, IgxDialogModule, IgxGridComponent, IgxIconComponent, IgxIconModule, IgxInputGroupModule, IgxRippleModule, IgxSimpleComboComponent, IgxTooltipDirective, IgxTooltipTargetDirective, IRowSelectionEventArgs } from '@infragistics/igniteui-angular';
import { CommonModule, NgClass } from '@angular/common';
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import { DatalexDateTimeStringToTimestamp, toLocalIsoString } from 'src/app/util/DatalexDateTime';
import { ButtonRowButtonComponent } from "../button-row-button/button-row-button.component";
import { GridCountDirective } from 'src/app/directives/grid-count.directive';
import { GridFilterInputComponent } from "../grid-filter-input/grid-filter-input.component";
import { ExportToExcelDirective } from 'src/app/directives/export-to-excel.directive';
import { DocumentHandlerService } from 'src/app/services/document-handler.service';
import { DocumentDetailEditorComponent } from "../../pages/selected-case-page/document/document-detail-editor/document-detail-editor.component";
import { IDocumentLimitedGrid } from '../../pages/selected-case-page/document/document-grid/document-grid.component';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';
import { ActivityLogService } from '../activity-log-overlay/activity-log.service';
import { Router } from '@angular/router';
import newActivity from 'src/app/classes/Activity/Actvity';
import { SystemDialogEnum } from 'src/app/util/SystemDialogEnum';
import { ClearGridFiltersDirective } from 'src/app/directives/clear-grid-filters.directive';
import { fileIcon } from 'src/app/util/FileIcons';
import { ImageDialogComponent } from "../image-dialog/image-dialog.component";
import { DatalexMsalService } from 'src/app/services/datalex-msal.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { MicrosoftGraphService } from 'src/app/services/graph.service';
import { getDrives } from '../../pages/test-page/graphMethods';
import { MicrosoftGraph } from 'src/app/classes/MicrosoftGraph';
import { DriveItem } from 'src/models/MicrosoftGraphReturnTypes';
import FileManager from 'src/app/classes/FileManager';
import { getMimeTypeFromFilename } from 'src/app/util/FileMimeTypeUtil';
import { UserAreaEnum, UserRightTypeEnum } from 'src/app/util/UserRightUtil';

interface IDocumentSearchCriteria {
  dateFrom: string | null;
  dateTo: string | null;
  indexId: string | null;
  voucherNumber: number | null;
  financialYear: number | null;
  authorId: string | null;
  documentTypes: string[];
  hasAttachment: boolean | null;
  documentCategoryId: string | null;
  documentSubCategoryId: string | null;
  searchWord1: string;
  searchWord2: string;
  searchWord3: string;
  searchWithOr: boolean | null;
  fileMustNotContain: string;
  searchTitle: boolean | null;
  searchDescription: boolean | null;
  searchContent: boolean | null;
  searchFileName: boolean | null;
}

type IncludeLiteral = 1 | 2 | 3 | 4 | 5;

interface ISearchInTypes {
  name: string;
  id: string;
  includeWith: IncludeLiteral[];
}

interface ISearchAuthors {
  name: string | null;
  id: string | null;
}

export interface IGridRowData extends IDocumentLimitedBE {
  Icon: string;
}


@Component({
  selector: 'app-document-search',
  standalone: true,
  imports: [FormsModule, CommonModule, ToggleSwitchComponent, IgxButtonGroupModule, NgClass, IgxDialogModule, IgxInputGroupModule, IgxSimpleComboComponent, IgxComboComponent, IgxTooltipTargetDirective, IgxTooltipDirective, IgxDatePickerModule, IgxIconComponent, IgxButtonModule, IgxIconModule, IgxRippleModule, IgxGridComponent, IgxColumnComponent, ButtonRowButtonComponent, GridCountDirective, GridFilterInputComponent, ExportToExcelDirective, DocumentDetailEditorComponent, ClearGridFiltersDirective, IgxCellTemplateDirective, IgxCellHeaderTemplateDirective, ImageDialogComponent],
  templateUrl: './document-search.component.html',
  styleUrl: './document-search.component.scss'
})
export class DocumentSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(private dlxClient: DatalexClient,
    private sys: SystemCacheService,
    public combo: ComboSelectFunctionsService,
    public documentHandler: DocumentHandlerService,
    private rights: UserRightsProviderService,
    private als: ActivityLogService,
    private router: Router,
    private dlxMsal: DatalexMsalService,
    private graph: MicrosoftGraphService,
  ) {

    this.subscriptions.add(this.dlxMsal.isLoggedInToSharePoint$.subscribe({
      next: value => {
        if (value === null) return
        this.isLoggedInSharepoint = value.isLoggedIn;
      }
    }
    ));
  }
  vdate = DatalexDateTimeStringToTimestamp;
  // @ViewChild('searchWordsInput') searchWordsInput!: ElementRef;
  @ViewChild('documentSearchDialog') dialog!: IgxDialogComponent;

  @ViewChild('detailModal', { static: true }) detailModal!: IgxDialogComponent;
  @ViewChild('imageDialog', { static: true }) imageDialog!: ImageDialogComponent;

  // @ViewChild('searchResult', { static: true }) grid!: IgxGridComponent;
  @ViewChild('searchResult', { read: IgxGridComponent }) public grid!: IgxGridComponent;

  // selectedDocument!: IDocumentLimitedGrid | null;

  subscriptions = new Subscription();
  isLoggedInSharepoint: boolean = false;

  showResults: boolean = false;

  private inputFocusListeners: (() => void)[] = [];
  private inputBlurListeners: (() => void)[] = [];

  showDetailModal: boolean = false;
  documentSubCategories: IDocumentSubCategoryBE[] = [];
  caseRights = 'NONE';
  caseDocumentRights = false;
  contactDocumentRights = false;
  accountingDocumentRights = false;

  selectedRowCount: number = 0;
  searchCompleted: boolean = false;

  canOpenDocuments: boolean = false;
  isOpenCaseEnabled: boolean = false;
  isConvertToPdfEnabled: boolean = false;
  isInUse: boolean = false;


  ngOnInit(): void {
    this.loadUserRights();
    try {
      this.initializeComponent();
    }
    catch {
      this.sys.isReady.subscribe({
        next: () => {
          this.initializeComponent();
        }
      });
    }
  }
  loadUserRights() {
    try {
      this.caseRights = this.rights.checkAccess(this.rights.UserAreaEnum.CASE_MANAGMENT).accessName;
      this.caseDocumentRights = this.rights.checkAccess(this.rights.UserAreaEnum.CASE_DOCUMENTS).accessName === "FULL";
      this.contactDocumentRights = this.rights.checkAccess(this.rights.UserAreaEnum.CONTACT_DOCUMENTS).accessName === "FULL";
    } catch (error) {
      this.sys.isReady.subscribe(() => {
        this.caseRights = this.rights.checkAccess(this.rights.UserAreaEnum.CASE_MANAGMENT).accessName;
        this.caseDocumentRights = this.rights.checkAccess(this.rights.UserAreaEnum.CASE_DOCUMENTS).accessName === "FULL";;
        this.contactDocumentRights = this.rights.checkAccess(this.rights.UserAreaEnum.CONTACT_DOCUMENTS).accessName === "FULL";;
      });
    }
  }

  ngAfterViewInit(): void {
    // const inputElements = this.searchWordsInput.nativeElement.querySelectorAll('.input-group input');
    // inputElements.forEach((input: HTMLInputElement) => {
    //   const focusListener = () => {
    //     this.searchWordsInput.nativeElement.classList.add('focused');
    //     this.setLabelActiveClass(input);
    //   };

    //   const blurListener = () => {
    //     this.searchWordsInput.nativeElement.classList.remove('focused');
    //     this.setLabelInactiveClass(input);
    //   };

    //   input.addEventListener('focus', focusListener);
    //   input.addEventListener('blur', blurListener);

    //   this.inputFocusListeners.push(() => input.removeEventListener('focus', focusListener));
    //   this.inputBlurListeners.push(() => input.removeEventListener('blur', blurListener));
    // });
    this.dialog.open();
  }

  setLabelActiveClass(input: HTMLInputElement) {
    if (input && input.previousElementSibling) {
      input.previousElementSibling.classList.remove('label-with-value');
      (input.previousElementSibling as HTMLLabelElement).classList.add('label-focused');
    }
  }
  setLabelInactiveClass(input: HTMLInputElement) {
    if (input && input.previousElementSibling) {
      if (input.value) input.previousElementSibling.classList.add('label-with-value');
      else input.previousElementSibling.classList.remove('label-with-value');

      (input.previousElementSibling as HTMLLabelElement).classList.remove('label-focused');
    }
  }

  ngOnDestroy() {
    this.inputFocusListeners.forEach(removeListener => removeListener());
    this.inputBlurListeners.forEach(removeListener => removeListener());
  }

  @Output() results: EventEmitter<IDocumentLimitedBE[]> = new EventEmitter<IDocumentLimitedBE[]>();

  isLoading: boolean = false;

  includeDocuments: boolean = true;
  includeIncommingEmail: boolean = false;
  includeOutgoingEmail: boolean = false;
  includeNotes: boolean = false;
  includeOutgoingInvoice: boolean = false;

  now = new Date();
  firstDayofYear = new Date(this.now.getFullYear(), 0, 1);

  authors: ISearchAuthors[] = [];

  data: IDocumentLimitedBE[] = [];
  dataWithIcons: IGridRowData[] = [];

  NoResultsText: string = 'Ingen treff prøv å endre søketkriteriene.';

  initializeComponent() {
    if (!this.sys.allEmployees) throw new Error('SystemCacheService.allEmployees not ready');

    const users = this.sys.allEmployees.map((emp: IUserLimitedBE) => ({ name: emp.ContactName, id: emp.ContactId }));
    this.authors = [{ name: null, id: null }, ...users]
    this.dlxClient.GetDocumentTypes().subscribe({
      next: (res) => {
        this.documentTypes = res.map((type) => ({ name: type.Name, id: type.Id, includeWith: [1] }));
        this.staticTypes.forEach(staticType => {
          for (let i = 0; i < this.documentTypes.length; i++) {
            if (this.documentTypes[i].id.toUpperCase() === staticType.id.toUpperCase()) {
              this.documentTypes[i].includeWith = staticType.includeWith;
              break;
            }
          }
        });
        this.updateDocumentTypes();
      }
    })

  }

  updateDocumentTypes() {
    const filters: IncludeLiteral[] = [];
    if (this.includeDocuments) filters.push(1);
    if (this.includeIncommingEmail) filters.push(2);
    if (this.includeOutgoingEmail) filters.push(3);
    if (this.includeNotes) filters.push(4);
    if (this.includeOutgoingInvoice) filters.push(5);

    const typeIdArray: string[] = [];
    this.documentTypes.forEach(type => {
      if (type.includeWith.some(include => filters.includes(include))) typeIdArray.push(type.id);
    })

    this.searchCriteria.documentTypes = typeIdArray;
  }

  clearDocumentTypes() {
    this.searchCriteria.documentTypes = []
    this.includeIncommingEmail = this.includeOutgoingEmail = this.includeNotes = this.includeOutgoingInvoice = false;
    this.includeDocuments = true;
  }


  attemptIncludeOutgoingInvoiceChange(event: Event) {
    if (this.rights.checkAccess(this.rights.UserAreaEnum.ACCOUNT_DOCUMENTS).accessId.toUpperCase() === UserRightTypeEnum.NONE.toUpperCase()) {
      event.preventDefault();
      const missingAccess = newActivity({
        message: `Du har ikke tilgang til å søke på regnskaps dokumenter.`,
        type: 'warning',
        timestamp: new Date(),
      })
      this.als.appendActivity(missingAccess)
    }
  }


  attemptIncludeAccountingDocuments(event: IComboSelectionChangingEventArgs) {
    if (event.newSelection > event.oldSelection) {
      const isInvoice = event.added.find(type => {
        return type.id.toUpperCase() === DocumentTypeEnum.UT_FAKTURA.toUpperCase() ||
          type.id.toUpperCase() === DocumentTypeEnum.UT_FAKTURA_SYS_GEN.toUpperCase()
      })

      if (isInvoice && this.rights.checkAccess(this.rights.UserAreaEnum.ACCOUNT_DOCUMENTS).accessId.toUpperCase() === UserRightTypeEnum.NONE.toUpperCase()) {
        event.cancel = true;
      }

      // event.cancel = !event.added.filter(type => type.id === DocumentTypeEnum.UT_FAKTURA || type.id === DocumentTypeEnum.UT_FAKTURA_SYS_GEN)
    }
    if (event.cancel) {
      const missingAccess = newActivity({
        message: `Du har ikke tilgang til å søke på regnskaps dokumenter.`,
        type: 'warning',
        timestamp: new Date(),
      })
      this.als.appendActivity(missingAccess)
    }

  }


  staticTypes: ISearchInTypes[] = [
    { name: "Brev", id: DocumentTypeEnum.BREV, includeWith: [1] },
    { name: "Prosess", id: DocumentTypeEnum.PROSSESSKRIV, includeWith: [1] },
    { name: "Diverse", id: DocumentTypeEnum.DIVERSE, includeWith: [1] },
    { name: "E-post", id: DocumentTypeEnum.EPOST, includeWith: [2, 3] },
    { name: "Notat", id: DocumentTypeEnum.NOTAT, includeWith: [4] },
    { name: "Bilde", id: DocumentTypeEnum.BILDE, includeWith: [1] },
    { name: "Utgående faktura", id: DocumentTypeEnum.UT_FAKTURA, includeWith: [5] },
    { name: "Omkostningsoppgave", id: DocumentTypeEnum.OMKOSTNINGSOPPGAVE, includeWith: [1] },
    { name: "Faks", id: DocumentTypeEnum.FAKS, includeWith: [1] },
    { name: "Sikker sending", id: DocumentTypeEnum.SIKKER_SENDING, includeWith: [1] },
    { name: "SignRet", id: DocumentTypeEnum.SIGN_RET, includeWith: [1] },
    { name: "Signert", id: DocumentTypeEnum.SIGNERT, includeWith: [1] },
    { name: "Digipost", id: DocumentTypeEnum.DIGIPOST, includeWith: [1] },
    { name: "Slettet", id: DocumentTypeEnum.SLETTET, includeWith: [1] },
    /* note: disse må sjekkes senere */
    { name: "Avtale (BREV)", id: DocumentTypeEnum.BREV, includeWith: [1] },
    { name: "Oppdragsbekreftelse (BREV)", id: DocumentTypeEnum.BREV, includeWith: [1] },
    /* note end */
    { name: "Utgående faktura (systemgenerert)", id: DocumentTypeEnum.UT_FAKTURA_SYS_GEN, includeWith: [5] },
  ]

  documentTypes: ISearchInTypes[] = [];

  readonly searchCriteriaBase: IDocumentSearchCriteria = {
    dateFrom: null,
    dateTo: null,
    indexId: null,
    voucherNumber: null,
    financialYear: null,
    authorId: null,
    documentTypes: [],
    hasAttachment: null,
    documentCategoryId: null,
    documentSubCategoryId: null,
    searchWord1: '',
    searchWord2: '',
    searchWord3: '',
    searchWithOr: true,
    fileMustNotContain: '',
    searchTitle: true,
    searchDescription: true,
    searchContent: true,
    searchFileName: true
  }

  searchCriteria: IDocumentSearchCriteria = {
    dateFrom: null,
    dateTo: null,
    indexId: null,
    voucherNumber: null,
    financialYear: null,
    authorId: null,
    documentTypes: [],
    hasAttachment: null,
    documentCategoryId: null,
    documentSubCategoryId: null,
    searchWord1: '',
    searchWord2: '',
    searchWord3: '',
    searchWithOr: true,
    fileMustNotContain: '',
    searchTitle: true,
    searchDescription: true,
    searchContent: true,
    searchFileName: true
  }

  setFromDate(event: Date) {
    this.searchCriteria.dateFrom = toLocalIsoString(event, true)
  }

  setToDate(event: Date) {
    this.searchCriteria.dateTo = toLocalIsoString(event, true)
  }

  submitSearch() {

    this.onSearch();
  }

  onSearch() {
    let documentTypes = Array.from(new Set<string>(this.searchCriteria.documentTypes));

    this.dlxClient.FindDocuments(
      this.searchCriteria.dateFrom,
      this.searchCriteria.dateTo,
      this.searchCriteria.indexId,
      this.searchCriteria.voucherNumber,
      this.searchCriteria.financialYear,
      this.searchCriteria.authorId,
      documentTypes,
      this.searchCriteria.hasAttachment,
      this.searchCriteria.documentCategoryId,
      this.searchCriteria.documentSubCategoryId,
      this.searchCriteria.searchWord1,
      this.searchCriteria.searchWord2,
      this.searchCriteria.searchWord3,
      this.searchCriteria.searchWithOr,
      this.searchCriteria.fileMustNotContain,
      this.searchCriteria.searchTitle,
      this.searchCriteria.searchDescription,
      this.searchCriteria.searchContent,
      this.searchCriteria.searchFileName).subscribe({
        next: (data) => {

          if (this.includeIncommingEmail === false && this.includeOutgoingEmail === false && this.searchCriteria.documentTypes.includes(DocumentTypeEnum.EPOST) === false) {

            data = data.filter(doc => doc.DocumentTypeId !== DocumentTypeEnum.EPOST);

          } else if (this.includeIncommingEmail === true && this.includeOutgoingEmail === false) {
            data = data.filter(doc => {
              return doc.DocumentTypeId !== DocumentTypeEnum.EPOST ? true : doc.IsOut === false;

            });
          } else if (this.includeIncommingEmail === false && this.includeOutgoingEmail === true) {
            data = data.filter(doc => {
              return doc.DocumentTypeId !== DocumentTypeEnum.EPOST ? true : doc.IsOut === true;

            });
          }

          /*
                this.caseDocumentRights = this.rights.checkAccess(this.rights.UserAreaEnum.CASE_DOCUMENTS).accessName === "FULL";
                this.contactDocumentRights = this.rights.checkAccess(this.rights.UserAreaEnum.CONTACT_DOCUMENTS).accessName === "FULL";
          */

          if (this.rights.checkAccess(UserAreaEnum.CASE_DOCUMENTS).accessId.toUpperCase() === UserRightTypeEnum.NONE.toUpperCase()) {
            data = data.filter(doc => doc.CaseId === null);
          }
          if (this.rights.checkAccess(UserAreaEnum.CONTACT_DOCUMENTS).accessId.toUpperCase() === UserRightTypeEnum.NONE.toUpperCase()) {
            data = data.filter(doc => doc.ContactId === null);
          }


          if (this.showResults) {
            this.searchCompleted = true;
            this.data = data;
            this.dataWithIcons = this.addRowIcons(this.data);

            this.setGridEvents();
          }

          this.results.emit(data);
        },
        complete: () => {
          this.isLoading = false;
          this.searchCompleted = true;

        }
      })

  }

  clearSearch() {
    this.searchCriteria = JSON.parse(JSON.stringify(this.searchCriteriaBase));
    this.clearDocumentTypes();
    this.data = [];
    this.dataWithIcons = [];
    this.updateDocumentTypes();
    this.searchCompleted = false;
  }


  unlockDocument() {
    const selectedRows = this.getSelectedRows();

    if (!selectedRows || selectedRows.length === 0) {
      console.error('No documents selected for unlocking');
      return;
    }

    const lockedDocuments = selectedRows.filter(doc => doc.InUseBy && doc.InUseBy !== '');

    if (lockedDocuments.length === 0) {
      return;
    }

    this.documentHandler.unlockDocuments(lockedDocuments).subscribe({
      next: (document) => {
        this.grid.updateRow(document.Id, document);
      },
      error: (err: any) => {
        console.error('Error unlocking document(s):', err);
      },
      complete: () => {
      }
    });
  }

  openDocuments() {
    const selectedRows = this.getSelectedRows();

    if (!selectedRows || selectedRows.length === 0) {
      console.error('No documents selected for opening');
      return;
    }

    if (!this.caseDocumentRights && !this.contactDocumentRights) {
      alert('You do not have sufficient permissions to open these documents.');
      return;
    }

    //hvis kun ett notat er valgt
    if(false) {
      this.openDetailsModal();
    }

    const imageFormats = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'tif'];
    const imageDocuments = selectedRows.filter(doc =>
      imageFormats.some(format => doc.Filename?.toLowerCase().endsWith(format))
    );

    this.documentHandler.openDocuments(selectedRows).subscribe({
      next: (document) => {
        if (imageDocuments.includes(document)) {
          this.imageDialog.addImage(document);
        }

        this.grid.updateRow(document.Id, document);
      },
      error: (errMessage) => {
        const activity = newActivity({
          message: `Du har ikke tilgang til å åpne kontakt dokumenter.`,
          type: 'warning',
          timestamp: new Date(),
        });
        this.als.appendActivity(activity);
      },
      complete: () => {
        if (imageDocuments.length > 0) {
          this.imageDialog.openDialog();
        }
      },
    });
  }


  // deleteDocument() {
  //   const selectedRows = this.getSelectedRows();
  //   if (!selectedRows || selectedRows.length === 0) {
  //     console.error('No documents selected for deletion');
  //     return;
  //   }

  //   this.documentHandler.deleteDocuments(selectedRows).subscribe({
  //     next: (currentDocument) => {
  //       this.grid.deleteRow(currentDocument.Id);
  //     },
  //     error: (errMessage) => {
  //       console.error(errMessage);
  //     }
  //   });
  // }


  deleteDocument() {
    const selectedRows = this.getSelectedRows();

    if (!selectedRows || selectedRows.length === 0) {
      console.error('No documents selected for deletion');
      return;
    }

    const confirmDelete = window.confirm(
      `Er du sikker på at du vil slette ${selectedRows.length} dokument${selectedRows.length > 1 ? 'er' : ''}?`
    );
    if (!confirmDelete) {
      return;
    }

    this.documentHandler.deleteDocuments(selectedRows).subscribe({
      next: (deletedDocument) => {
        console.log(`Deleted document: ${deletedDocument.DocumentName}`);
        this.grid.deleteRow(deletedDocument.Id);
      },
      error: (errMessage) => {
        console.error('Error during document deletion:', errMessage);
        alert('Noen dokumenter kunne ikke slettes. Sjekk rettighetene dine.');
      },
      complete: () => {
        console.log('All selected documents processed for deletion.');
        this.grid.deselectAllRows();
        this.selectedRowCount = 0;
      },
    });
  }



  openCase() {
    const selectedRows = this.getSelectedRows();
    if (!selectedRows) return;
    if (!selectedRows[0] || !selectedRows[0].CaseId) {
      // Handle the case where CaseId is null or the document is not selected
      const activity = newActivity({
        message: `Dokumentet har ingen gyldig sak tilknyttet.`,
        type: 'failure',
        timestamp: new Date(),
      });
      this.als.appendActivity(activity);
      return;
    }

    if (this.caseRights === 'NONE') {
      const activity = newActivity({
        message: `Ingen tilgang på området "Saksbehandling".`,
        type: 'failure',
        timestamp: new Date(),
      });
      this.als.appendActivity(activity);
      return;
    }

    if (this.caseRights === 'OWN_CASES') {
      this.dlxClient.GetCaseLimited(selectedRows[0].CaseId).subscribe({
        next: (response) => {
          if (this.rights.checkCaseAccessOwnCases({ sa: response.CaseResponsibleId!, sb: response.CaseHandlerId }, this.sys.loggedinUserContact.Id)) {
            this.router.navigate(['/proxy', 'case', response.Id]);

          } else {
            const activity = newActivity({
              message: `Ingen tilgang, du har kun tilgang til egne saker.`,
              type: 'failure',
              timestamp: new Date(),
            });
            this.als.appendActivity(activity);

            alert('Du har ikke tilgang til denne saken. Du har kun tilgang til egne saker.');
          }
        },
        error: (err) => {
          console.error('Error fetching case details:', err);
        },
      });
      return;
    }

    this.router.navigate(['/proxy', 'case', selectedRows[0].CaseId!]);
    this.dialog.close();
  }


  openContact() {
    const selectedRows = this.getSelectedRows();
    if (!selectedRows) return;
    if (!selectedRows[0] || !selectedRows[0].ContactId) {
      // Handle the case where CaseId is null or the document is not selected
      const activity = newActivity({
        message: `Dokumentet har ingen gyldig kontakt tilknyttet.`,
        type: 'failure',
        timestamp: new Date(),
      });
      this.als.appendActivity(activity);
      return;
    }

    if (this.rights.checkAccess(UserAreaEnum.CONTACTS).accessId.toUpperCase() === UserRightTypeEnum.NONE.toUpperCase()) {
      const activity = newActivity({
        message: `Ingen tilgang på området "Kontakt".`,
        type: 'failure',
        timestamp: new Date(),
      });
      this.als.appendActivity(activity);
      return;
    }

    this.router.navigate(['/proxy', 'contact', selectedRows[0].ContactId!]);

  }

  closeSearchDialog() {
    this.dialog.close();

    this.caseRights = '';
  }


  openDetailsModal() {
    const selectedRows = this.getSelectedRows();

    if (!selectedRows || selectedRows.length === 0) {
      console.error('No document selected to show details');
      return;
    }

    const selectedDocument = selectedRows[0];
    this.fetchSubCategories(selectedDocument.DocumentCategoryId);
    this.showDetailModal = true;

    this.detailModal.open();
  }

  private fetchSubCategories(categoryId: string | null): void {
    if (!categoryId) return;

    this.dlxClient.GetDocumentSubCategorysByCategory(categoryId).subscribe({
      next: (response) => {
        this.documentSubCategories = response;
      },
      error: (error) => console.error('Error fetching subcategories:', error)
    });
  }

  closeModal() {
    this.detailModal.close();
    this.showDetailModal = false;
    this.documentSubCategories = [];
  }

  getSelectedRows(): IDocumentLimitedBE[] {
    const selectedPrimaryKeys = this.grid.selectedRows as string[];

    if (!selectedPrimaryKeys || selectedPrimaryKeys.length === 0) { this.isOpenCaseEnabled = false; return [] };

    const selectedRows: IDocumentLimitedBE[] = [];
    selectedPrimaryKeys.forEach((key) => {
      const row = this.grid.getRowByKey(key).data as IDocumentLimitedBE;
      selectedRows.push(row);
    });

    if (selectedRows.length === 1 && selectedRows[0].CaseId) {
      this.isOpenCaseEnabled = true;
    } else {
      this.isOpenCaseEnabled = false;
    }

    return selectedRows;
  }


  private setGridEvents() {
    setTimeout(() => {
      try {
        this.grid.rowSelectionChanging.subscribe({
          next: (event: IRowSelectionEventArgs) => {
            this.selectedRowCount = event.newSelection.length;
            setTimeout(() => {
              this.canOpenDocuments = this.checkCanOpenDocuments();
              this.isInUse = this.anySelectedDocumentsLocked();
              this.isConvertToPdfEnabled = this.canConvertToPdf();
            }, 1)
          }
        })
      } catch (error) {
        console.error(error);
      }
    }, 100);
  }

  private addRowIcons(docs: IDocumentLimitedBE[]): IGridRowData[] {
    let tmp: IGridRowData[] = [];

    (docs as IGridRowData[]).forEach((item, index) => {
      let tempFile = item.Filename ? item.Filename!.split('.') : [""];
      if (item.DocumentTypeId === DocumentTypeEnum.NOTAT) {
        item.Icon = fileIcon(DocumentTypeEnum.NOTAT);
      }
      else {
        item.Icon = fileIcon(tempFile[tempFile.length - 1]);
      }

      tmp.push(item);

    })

    return tmp;
  }


  anySelectedDocumentsLocked(): boolean {
    const selectedRows = this.getSelectedRows();

    if (!selectedRows || selectedRows.length === 0) {
      return false;
    }

    return selectedRows.some(doc => doc.InUseBy && doc.InUseBy !== '');
  }

  updateRow() {

  }

  checkCanOpenDocuments(): boolean {
    if (!this.grid) {
      console.error('Grid is not initialized yet.');
      return false;
    }

    const selectedRows = this.getSelectedRows();
    if (!selectedRows || selectedRows.length === 0) {
      return false;
    }

    
    // Check if any selected document is not a Digipost document
    const validDocuments = selectedRows.filter(doc =>
      doc.DocumentTypeId.toUpperCase() !== DocumentTypeEnum.DIGIPOST.toUpperCase()
    );

    const multipleNoteDocuments = selectedRows.filter(doc => 
      doc.DocumentTypeId.toUpperCase() === DocumentTypeEnum.NOTAT.toUpperCase()
    );

    
    if(multipleNoteDocuments.length > 1 && selectedRows.length > 1) {
      return false;
    }


    return validDocuments.length > 0;
  }

  canConvertToPdf(): boolean {
    const selectedRows = this.getSelectedRows();
    return selectedRows.length === 1 && this.isConvertibleToPdf(selectedRows[0]);
  }

  isConvertibleToPdf(document: IDocumentLimitedBE): boolean {
    const convertibleTypes = ['docx', 'doc', 'xls', 'xlsx', 'eml', 'msg', 'odp', 'ods', 'odt', 'pptx', 'pps', 'ppt', 'rtf'];
    const fileExtension = this.getFileExtension(document.Filename);

    return convertibleTypes.includes(fileExtension);
  }


  getFileExtension(filename: string | undefined): string {
    if (!filename) return '';
    const split = filename.split('.');
    return split[split.length - 1].toLowerCase();
  }

  convertToPdf() {
    const selectedRows = this.getSelectedRows();
    if (!selectedRows || selectedRows.length === 0) {
      console.error('No documents selected for conversion');
      return;
    }

    this.documentHandler.convertToPdf(selectedRows[0]).subscribe({
      next: () => {
        this.submitSearch();
      }
    });

  }
}