<igx-dialog #documentSearchDialog>
  <div class="flex-col">
    <div class="dialog_header">
      <i translate="no" class="material-symbols-outlined" (click)="closeSearchDialog()">close</i>
    </div>
    <div class="dialog_content" [ngStyle]="{'width': showResults && data.length > 0 ? '1200px' : '600px'}">
      @if(showResults && data.length > 0 && !isLoading) {
      <div class="grid_wrapper">
        <div class="flex-row space-between dialog-header">
          <div class="back_wrapper" (click)="clearSearch(); ">
            <i translate="no" class="material-symbols-outlined" style="margin-right: .5rem;">arrow_back</i>
            <strong>Nytt søk</strong>
          </div>
          <div class="flex-row flex-center">
            <div class="result_title">
              <strong style="margin-right: .5rem;">Søkeresultat:</strong>
              <p>{{data.length}} treff</p>
            </div>
            <i translate="no" class="material-symbols-outlined" (click)="closeSearchDialog();">close</i>
          </div>
        </div>
        <div class="button-row">
          <div class="button-cluster">
            <app-button-row-button [enabled]="selectedRowCount  > 0 && isInUse" label="Lås opp" icon="lock_open"
              (click)="unlockDocument()" />
            <app-button-row-button [enabled]="canOpenDocuments" label="Åpne" icon="open_in_new"
              (click)="openDocuments()" />
            <app-button-row-button [enabled]="selectedRowCount > 0" label="Slett" icon="delete"
              (click)="deleteDocument()" />

            <app-button-row-button [enabled]="isOpenCaseEnabled" label="Åpne sak" icon="folder_open"
              (click)="openCase()" />
            <app-button-row-button [enabled]="selectedRowCount === 1" label="Detaljer" icon="info"
              (click)="openDetailsModal()" />
            <app-button-row-button [enabled]="isConvertToPdfEnabled" label="Konverter til Pdf" icon="picture_as_pdf"
              (click)="convertToPdf()" />

          </div>
          <div class="button-cluster">
            <app-button-row-button label="Excel" icon="table_view" dlxExcelHandler [grid]="searchResult"
              fileName="Dokumenter" />
          </div>
        </div>

        <igx-grid #searchResult [data]="dataWithIcons" width="100%" class="result-grid" primaryKey="Id"
          rowSelection="multiple" [allowFiltering]="true" [style.--ig-size]="'var(--ig-size-small)'">
          <igx-column field="Icon" header="&nbsp;" [width]="'32px'" [filterable]="false"
            [headerClasses]="'header-filter'">
            <ng-template igxHeader let-column>
              <div>
                <i translate="no" id="clear-filter" class="material-symbols-outlined grid-filter-clear-button"
                  [appClearGridFilters]="searchResult" #target="tooltipTarget"
                  [igxTooltipTarget]="tooltipRef">filter_alt_off</i>
                <div class="tooltip" #tooltipRef="tooltip" igxTooltip>
                  Klikk her for å blanke ut alle filter kriterier.
                </div>
              </div>
            </ng-template>

            <ng-template igxCell let-cell="cell">
              <div class="icon-cell-inner">
                <img [src]="cell.value" class="fileicon" />
              </div>
            </ng-template>
          </igx-column>


          <igx-column header="Type" width="80px" dataType="string" field="DocumentTypeName"
            [filterCellTemplate]="defaultFilterTemplate" />
          <igx-column header="Tittel" dataType="string" field="DocumentName"
            [filterCellTemplate]="defaultFilterTemplate" />
          <igx-column header="Sist endret" dataType="dateTime" field="DateChanged"
            [filterCellTemplate]="defaultFilterTemplate" />
          <igx-column header="Låst av" width="120px" dataType="string" field="InUseBy"
            [filterCellTemplate]="defaultFilterTemplate" />
          <igx-column header="Saksnr" width="80px" dataType="number" field="CaseNumber"
            [filterCellTemplate]="defaultFilterTemplate" />
          <igx-column header="K.nr" width="80px" dataType="number" field="ContactNumber"
            [filterCellTemplate]="defaultFilterTemplate" />
          <igx-column header="Filnavn" dataType="string" field="Filename"
            [filterCellTemplate]="defaultFilterTemplate" />
          <igx-column header="Dok.nr." width="80px" dataType="number" field="DocumentNr"
            [filterCellTemplate]="defaultFilterTemplate" />
        </igx-grid>
      </div>
      <ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
        <grid-filter-input [grid]="searchResult" [column]="column"></grid-filter-input>
      </ng-template>
      }
      <div class="search_include">
        <div class="search_option_title">
          <h3>Inkluder:</h3>
        </div>

        <div class="checkbox-buttons">
          <label for="documents" class="checkbox-wrapper" [ngClass]="includeDocuments ? 'checked' : ''">
            <span class="checkmark">Dokumenter</span>
            <input type="checkbox" [(ngModel)]="includeDocuments" (change)="updateDocumentTypes()" name="documents"
              id="documents">
          </label>

          <label for="incommingEmail" class="checkbox-wrapper" [ngClass]="includeIncommingEmail ? 'checked' : ''">
            <span>Innk. E-post</span>
            <input type="checkbox" [(ngModel)]="includeIncommingEmail" (change)="updateDocumentTypes()"
              name="incommingEmail" id="incommingEmail">
          </label>

          <label for="outgoingEmail" class="checkbox-wrapper" [ngClass]="includeOutgoingEmail ? 'checked' : ''">
            <span>Utg. E-post</span>
            <input type="checkbox" [(ngModel)]="includeOutgoingEmail" (change)="updateDocumentTypes()"
              name="outgoingEmail" id="outgoingEmail">
          </label>

          <label for="notes" class="checkbox-wrapper" [ngClass]="includeNotes ? 'checked' : ''">
            <span>Notater</span>
            <input type="checkbox" [(ngModel)]="includeNotes" (change)="updateDocumentTypes()" name="notes" id="notes">
          </label>
          <label for="outgoingInvoice" class="checkbox-wrapper" [ngClass]="includeOutgoingInvoice ? 'checked' : ''">
            <span>Utg. faktura</span>
            <input type="checkbox" [(ngModel)]="includeOutgoingInvoice"
              (click)="attemptIncludeOutgoingInvoiceChange($event)" (change)="updateDocumentTypes()"
              name="outgoingInvoice" id="outgoingInvoice">
          </label>
        </div>

      </div>

      <div class="search_option_title">
        <h3>Søkeparameter:</h3>
      </div>

      <div class="flex-row">
        <div class="input-wrapper">
          <igx-date-picker #fromPicker id="fromDate" inputFormat="dd.MM.yyyy" type="border" todayButtonLabel="I dag"
            cancelButtonLabel="Avbryt" (click)="fromPicker.open()" todayButtonLabel="I dag" cancelButtonLabel="Avbryt"
            [value]="searchCriteria.dateFrom ?? firstDayofYear" (valueChange)="setFromDate($event)"
            [style.--ig-size]="'var(--ig-size-small)'">
            <label igxLabel for="fromDate">Fra dato:</label>
            <igx-icon igxSuffix (click)="fromPicker.open()">today</igx-icon>
          </igx-date-picker>
        </div>
        <div class="input-wrapper">
          <igx-date-picker #toPicker id="toDate" inputFormat="dd.MM.yyyy" type="border" todayButtonLabel="I dag"
            cancelButtonLabel="Avbryt" (click)="toPicker.open()" todayButtonLabel="I dag" cancelButtonLabel="Avbryt"
            [value]="searchCriteria.dateTo ?? now" (valueChange)="setToDate($event)"
            [style.--ig-size]="'var(--ig-size-small)'">
            <label igxLabel for="toDate">Til dato:</label>
            <igx-icon igxSuffix (click)="toPicker.open()">today</igx-icon>
          </igx-date-picker>
        </div>
      </div>


      <div class="flex-row">
        <div class="input-wrapper">
          <igx-simple-combo #authorSelect [data]="authors" [type]="'border'" (opening)="combo.comboOpening(authorLabel)"
            (closing)="combo.comboClosing(authorLabel, searchCriteria.authorId)"
            (ngModelChange)="searchCriteria.authorId = $event" displayKey="name" valueKey="id"
            [(ngModel)]="searchCriteria.authorId" [style.--ig-size]="'var(--ig-size-small)'">
            <label #authorLabel igxLabel class="simple-select-label simple-select-label--selected">Forfatter:</label>
            @if(searchCriteria.authorId) {
            <igx-suffix>
              <igx-icon (click)="searchCriteria.authorId = null">clear</igx-icon>
            </igx-suffix>
            }
          </igx-simple-combo>
        </div>
        <div class="input-wrapper">
          <igx-combo #documentTypeSelect [data]="documentTypes" [type]="'border'" valueKey="id" displayKey="name"
            [filteringOptions]="{caseSensitive: false,filterable: true}"
            (opening)="combo.comboOpening(documentTypeLabel);"
            (closing)="combo.comboClosing(documentTypeLabel, searchCriteria.documentTypes)"
            searchPlaceholder="filter..."
            (selectionChanging)="combo.multiComboChanging($event, documentTypeLabel); attemptIncludeAccountingDocuments($event)"
            [(ngModel)]="searchCriteria.documentTypes" itemsWidth="350px" [style.--ig-size]="'var(--ig-size-small)'">
            <ng-template igxComboItem let-item let-key="valueKey">
              <div class="selectItem-content-wrapper" #target="tooltipTarget" [igxTooltipTarget]="tooltipRef">
                <span class="selectItem-name">{{item.name}}</span>
              </div>
              <div #tooltipRef="tooltip" igxTooltip style="padding-bottom: 1rem;">
                {{item.Name}}
              </div>
            </ng-template>
            <label #documentTypeLabel igxLabel class="simple-select-label">Dokumenttyper:</label>
            @if(searchCriteria.documentTypes.length > 0) {
            <igx-suffix>
              <igx-icon (click)="clearDocumentTypes()">clear</igx-icon>
            </igx-suffix>
            }
          </igx-combo>
        </div>
      </div>


      <div class="search_option_title">
        <h3>Søkeord:</h3>
        <app-toggle-switch class="searchWithOr-toggle" [labels]="['ELLER', 'OG']"
          (toggleStatus)="searchCriteria.searchWithOr = $event" />
      </div>


      <div class="flex-row">
        <div class="input-wrapper">
          <igx-input-group type="border" [style.--ig-size]="'var(--ig-size-small)'">
            <input igxInput [(ngModel)]="searchCriteria.searchWord1" type="text" name="word1" id="word1">
            <label igxLabel for="include">Ord 1:</label>
          </igx-input-group>

        </div>
        <div class="input-wrapper">
          <igx-input-group type="border" [style.--ig-size]="'var(--ig-size-small)'">
            <input igxInput [(ngModel)]="searchCriteria.searchWord2" type="text" name="word2" id="word2">
            <label igxLabel for="include">Ord 2:</label>
          </igx-input-group>
        </div>
        <div class="input-wrapper">
          <igx-input-group type="border" [style.--ig-size]="'var(--ig-size-small)'">
            <input igxInput [(ngModel)]="searchCriteria.searchWord3" type="text" name="word3" id="word3">
            <label igxLabel for="include">Ord 3:</label>
          </igx-input-group>
        </div>


        <!-- <div #searchWordsInput class="search_words_input">
        <div class="input-group">
          <label for="word1">Ord 1:</label>
          <input [(ngModel)]="searchCriteria.searchWord1" type="text" name="word1" id="word1">
        </div>
        <span>&mdash;</span>
        <div class="input-group">
          <label for="word2">Ord 2:</label>
          <input [(ngModel)]="searchCriteria.searchWord2" type="text" name="word2" id="word2">
        </div>
        <span>&mdash;</span>
        <div class="input-group">
          <label for="word3">Ord 3:</label>
          <input [(ngModel)]="searchCriteria.searchWord3" type="text" name="word3" id="word3">
        </div>
      </div> -->
      </div>
      <div class="input-wrapper">
        <igx-input-group type="border" [style.--ig-size]="'var(--ig-size-small)'">
          <input igxInput [(ngModel)]="searchCriteria.fileMustNotContain" type="text" name="exclude" id="exclude">
          <label igxLabel for="include">Ekskluder:</label>
        </igx-input-group>

      </div>


      <div>
        <div class="search_option_title">
          <h3>Søk i:</h3>
        </div>
        <div class="checkbox-buttons">
          <label for="documenTitle" class="checkbox-wrapper" [ngClass]="searchCriteria.searchTitle ? 'checked' : ''">
            <span class="checkmark">Tittel</span>
            <input type="checkbox" [(ngModel)]="searchCriteria.searchTitle" name="documenTitle" id="documenTitle">
          </label>

          <label for="documentDescription" class="checkbox-wrapper"
            [ngClass]="searchCriteria.searchDescription ? 'checked' : ''">
            <span>Beskrivelse</span>
            <input type="checkbox" [(ngModel)]="searchCriteria.searchDescription" name="documentDescription"
              id="documentDescription">
          </label>

          <label for="documentContent" class="checkbox-wrapper"
            [ngClass]="searchCriteria.searchContent ? 'checked' : ''">
            <span>Innhold</span>
            <input type="checkbox" [(ngModel)]="searchCriteria.searchContent" name="documentContent"
              id="documentContent">
          </label>

          <label for="filename" class="checkbox-wrapper" [ngClass]="searchCriteria.searchFileName ? 'checked' : ''">
            <span>Filnavn</span>
            <input type="checkbox" [(ngModel)]="searchCriteria.searchFileName" name="filename" id="filename">
          </label>

        </div>
      </div>


      <div class="input-wrapper">
        <div class="bottom-container">
          <div class="noResult-container">
            @if(searchCompleted && data.length === 0) {
            <p>{{NoResultsText}}</p>
            }
          </div>
          <div class="search-actions">
            <button igxButton="contained" igxRipple="white" [style.background]="'#575757'" [style.color]="'white'"
              (click)="clearSearch()">Nullstill søk </button>
            <button igxButton="contained" [disabled]="searchCriteria.documentTypes.length <= 0" igxRipple="white"
              [style.background]="searchCriteria.documentTypes.length <= 0 ? '#BCBCBC' : '#aec965'"
              [style.color]="'white'" (click)="submitSearch()">{{isLoading ? 'SØKER' :
              'SØK'}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</igx-dialog>

<igx-dialog #detailModal [closeOnOutsideSelect]="false" leftTopLabel="OK" (leftBottomSelect)="closeModal() ">
  <igx-dialog-title>
    <div class="dialog-title-container">
      <div class="dialog-title">Dokumentdetaljer</div>
      <i translate="no" class="material-symbols-outlined" (click)="closeModal()">close</i>
    </div>
  </igx-dialog-title>
  <app-document-detail-editor *ngIf="showDetailModal" [data]="getSelectedRows()[0]"
    [subCategories]="documentSubCategories" (close)="closeModal()" (update)="updateRow()"
    [gotDocumentFullPermissions]="getSelectedRows()[0].CaseNumber ? !!caseDocumentRights : !!contactDocumentRights"
    (openFileEvent)="openDocuments()">
  </app-document-detail-editor>
</igx-dialog>

<dlx-image-dialog #imageDialog />