import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, ViewChild, OnInit, inject, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { DatalexClient, IDocumentLimitedBE } from '@datalex-software-as/datalex-client';
import { IgxCarouselComponent, IgxCarouselModule, IgxDialogComponent, IgxTooltipModule } from '@infragistics/igniteui-angular';



export interface ICarouselImage {
  dataUrl: string;
  title: string;
  description?: string;
}

const data = {} as IDocumentLimitedBE;

@Component({
  selector: 'dlx-image-dialog',
  standalone: true,
  imports: [IgxDialogComponent, IgxCarouselModule, IgxTooltipModule],
  templateUrl: './image-dialog.component.html',
  styleUrl: './image-dialog.component.scss'
})
export class ImageDialogComponent implements AfterViewInit {
  dlxClient = inject(DatalexClient);
  private cdr = inject(ChangeDetectorRef);
  @ViewChild('viewer', { static: true }) carousel!: IgxCarouselComponent;
  @ViewChild('dialog', { static: true }) dialog!: IgxDialogComponent;
  @ViewChild('dialog', { read: ElementRef }) dialogElement!: ElementRef;

  ngAfterViewInit(): void {
    // this.carousel.nativeElement.focus();
  }

  @Input() images: ICarouselImage[] = [

  ];


  public addImage(image: IDocumentLimitedBE) {
    this.getImageById(image);
  }

  public openDialog() {
    this.images = [];
    this.dialog.open();
  }
  public closeDialog() {
    this.dialog.close();
  }

  public nextImage() {
    this.carousel.next();
  }

  public prevImage() {
    this.carousel.prev();
  }

  private getImageById(imageBE: IDocumentLimitedBE): void {
    if (imageBE.FileId) {
      this.dlxClient.GetFileContent(imageBE.FileId).subscribe({
        next: async (event) => {
          if (event.type === HttpEventType.Response) {
            const response = event as HttpResponse<Blob>;
            const fileData = response.body
            if (fileData) {
              const namePart = imageBE.Filename.split('.');
              const extension = namePart[namePart.length - 1];
              const blob = new Blob([fileData], { type: `image/${extension}` });
              const dataUrl = await this.getDataUrl(blob);
              if (dataUrl) {

                this.images.push({ dataUrl: dataUrl, title: imageBE.DocumentName })

              }
              setTimeout(() => {
                console.log(this);
              }, 100);


            }
          }
        }
      });
    };
  }

  async getDataUrl(fileData: Blob): Promise<string | void> {
    try {
      const dataurl = await this.blobToBase64(fileData)
      return decodeURIComponent(dataurl);
    } catch (e) {
      console.error('Error while converting blob to base64:', e);
    }
  }


  blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('Failed to convert blob to base64 string'));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  downloadImage() {
    if (this.images.length > 0) {
      const currentImage = this.images[this.carousel.current]; // Get the current image in the carousel

      // Extract the file extension from the dataUrl's MIME type
      const mimeType = currentImage.dataUrl.split(',')[0].split(':')[1].split(';')[0];
      const extension = mimeType.split('/')[1]; // Get extension from MIME type, e.g., "image/png" -> "png"

      const fileName = `${currentImage.title}.${extension}`; // Use the title with the correct extension

      // Convert the data URL to a Blob and trigger the download
      const fileData = this.convertDataUrlToBlob(currentImage.dataUrl);
      this.downloadFile(fileData, fileName);
    }
  }

  convertDataUrlToBlob(dataUrl: string): Blob {
    const byteString = atob(dataUrl.split(',')[1]); // Decode base64 string
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0]; // Extract MIME type
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([uint8Array], { type: mimeString }); // Return Blob with correct MIME type
  }

  downloadFile(fileData: Blob, fileName: string) {
    const url = window.URL.createObjectURL(fileData); // Create a URL for the Blob
    const a = document.createElement('a'); // Create a temporary anchor element
    a.href = url;
    a.download = fileName; // Set the file name with extension
    document.body.appendChild(a); // Append the anchor to the body
    a.click(); // Trigger the download
    window.URL.revokeObjectURL(url); // Revoke the URL to free up memory
    document.body.removeChild(a); // Remove the anchor element
  }


}