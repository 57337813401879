import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, HostListener, Input, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { DocumentSearchComponent } from '../components/UI/document-search/document-search.component';
import { IDocumentLimitedBE } from '@datalex-software-as/datalex-client';

@Directive({
  selector: '[dlxDocumentSearch]',
  standalone: true
})
export class DocumentSearchDirective {

  constructor(private host: ViewContainerRef, private cdr: ChangeDetectorRef) {
  }


  @Input() indexId: string | null = null;
  @Input() showResults: boolean = false;
  @Output() result: EventEmitter<IDocumentLimitedBE[]> = new EventEmitter<IDocumentLimitedBE[]>();

  @HostListener('click') onClick() {
    this.host.clear();

    const component = this.host.createComponent(DocumentSearchComponent);

    this.cdr.detectChanges();

    component.instance.searchCriteria.indexId = this.indexId;
    component.instance.showResults = this.showResults;
    component.instance.dialog.closed.subscribe({
      next: () => {
        this.host.clear();
      }
    })

    component.instance.results.subscribe({
      next: (result: IDocumentLimitedBE[]) => {

        this.result.emit(result)


        if (this.showResults !== true) {
          component.instance.dialog.close();
        }

      }
    })
  }



}
