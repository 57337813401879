<div class="docInfoContainer">

  <div *ngIf="datasource.length > 0 || noResult || showContactSearch" class="contact-grid">

    <div class="contact-search-header">
      <igx-input-group type="border" [style.--ig-size]="'var(--ig-size-small)'">
        <input igxInput name="recipient" type="text" [(ngModel)]="recipientName"
          (keyup.enter)="lookupContact(recipientName!)" />
        <label igxLabel for="recipient">Navn:</label>
        <igx-icon igxSuffix (click)="lookupContact(recipientName!)">search</igx-icon>
      </igx-input-group>
      <span (click)="datasource = []; showContactSearch=false">Avbryt</span>
    </div>

    <igx-grid igxPreventDocumentScroll #contactGrid [autoGenerate]="false" [data]="datasource" [primaryKey]="'Id'"
      cellSelection="single" [allowFiltering]="true" (selected)="constactSelected($event)" [isLoading]="isLoading"
      [style.--ig-size]="'var(--ig-size-small)'"
      [emptyGridMessage]="searched ===true && datasource.length === 0 ? noResultsMessage : ' '">
      <ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
        <grid-filter-input [grid]="contactGrid" [column]="column"></grid-filter-input>
      </ng-template>
      <igx-column field="Number" header="Nr" width="60px" dataType="string" [editable]="false"
        [filterCellTemplate]="defaultFilterTemplate"></igx-column>
      <igx-column field="Name" header="Navn" dataType="string" [editable]="false"
        [filterCellTemplate]="defaultFilterTemplate"></igx-column>
    </igx-grid>

  </div>

  <igx-input-group class="w100 div1" type="border" [style.--ig-size]="'var(--ig-size-small)'">
    <input igxInput name="title" type="text" [(ngModel)]="title" [disabled]="!gotDocumentFullPermissions" required
      [class.invalid]="!title || title.trim().length === 0" />
    <label igxLabel for="title">Tittel:</label>
  </igx-input-group>


  <div class="simple-select-wrapper div2" *ngIf="users">
    <label #authorLabel igxLabel class="simple-select-label simple-select-label--selected">Avsender/Forfatter:</label>
    <igx-simple-combo class="simple-select-disable-reset " #rolle [data]="users" [type]="'border'"
      (opening)="combo.comboOpening(authorLabel)" (closing)="combo.comboClosing(authorLabel, author)"
      [displayKey]="'ContactName'" [valueKey]="'ContactId'" [(ngModel)]="author" (ngModelChange)="setAuthorName($event)"
      [disabled]="!gotDocumentFullPermissions" [style.--ig-size]="'var(--ig-size-small)'">
    </igx-simple-combo>
  </div>

  <igx-input-group class="div3" type="border" [style.--ig-size]="'var(--ig-size-small)'">
    <input igxInput name="recipient" type="text" [(ngModel)]="recipientName"
      (keyup.enter)="lookupContact(recipientName!)" [disabled]="!gotDocumentFullPermissions" />
    <label igxLabel for="recipient">Adressat:</label>

    @if(gotDocumentFullPermissions){<igx-icon igxSuffix (click)="lookupContact(recipientName!)">search</igx-icon>}
  </igx-input-group>

  <!-- <igx-input-group class="div3" type="border">
        <input igxInput name="recipient" type="text" [(ngModel)]="recipient" />
        <label igxLabel for="recipient">Adressat:</label>
    </igx-input-group> -->

  <igx-input-group class="div4" type="border" [style.--ig-size]="'var(--ig-size-small)'">
    <input igxInput [igxDateTimeEditor]="'dd.MM.yy  HH:mm'" [(ngModel)]="date" readonly />
    <label igxLabel for="date">Dato:</label>
  </igx-input-group>

  <igx-input-group class="div5" type="border" [style.--ig-size]="'var(--ig-size-small)'">
    <input igxInput [igxDateTimeEditor]="'dd.MM.yy  HH:mm'" [(ngModel)]="dateChanged" readonly />
    <label igxLabel>Endret dato:</label>
  </igx-input-group>

  <div class="simple-select-wrapper div6">
    <label #categoryLabel igxLabel class="simple-select-label simple-select-label--selected">Kategori:</label>
    <igx-simple-combo class="simple-select-disable-reset " #category [data]="documentCategories"
      defaultValue="categoryId" [type]="'border'" (opening)="combo.comboOpening(categoryLabel)"
      (closing)="combo.comboClosing(categoryLabel, categoryId)" [displayKey]="'Name'" [valueKey]="'Id'"
      [(ngModel)]="categoryId" (ngModelChange)="categoryChange($event)" value="categoryId"
      [disabled]="!gotDocumentFullPermissions" [style.--ig-size]="'var(--ig-size-small)'">
    </igx-simple-combo>
  </div>

  <div class="simple-select-wrapper div7">
    <label #subCategoryLabel igxLabel class="simple-select-label simple-select-label--selected">Under
      Kategori:</label>
    <igx-simple-combo class="simple-select-disable-reset " #subCategory [data]="subCategories"
      defaultValue="subCategoryId" [type]="'border'" (opening)="combo.comboOpening(subCategoryLabel)"
      (closing)="combo.comboClosing(subCategoryLabel, subCategoryId)" [displayKey]="'Name'" [valueKey]="'Id'"
      [(ngModel)]="subCategoryId" (ngModelChange)="subCategoryChange($event)" value="subCategoryId"
      [disabled]="categoryId === null || !gotDocumentFullPermissions " [style.--ig-size]="'var(--ig-size-small)'">
    </igx-simple-combo>
  </div>
  <div #subRef>
  </div>

  @if((data && isDocumentOfStaticType) || documentTypeId === DocumentTypeEnum.NOTAT) {
  <igx-input-group class="div8" type="border" [style.--ig-size]="'var(--ig-size-small)'">
    <input igxInput name="title" type="text"
      [value]="documentTypeId === DocumentTypeEnum.NOTAT ? 'Notat' : data?.DocumentTypeName" [disabled]="true" />
    <label igxLabel for="title">Dokumenttype:</label>
  </igx-input-group>

  } @else {
  <div class="simple-select-wrapper div8">
    <label #documentTypeLabel igxLabel class="simple-select-label simple-select-label--selected">Dokumenttype:</label>
    <igx-simple-combo class="simple-select-disable-reset " #documentType [data]="documentTypes" [type]="'border'"
      (opening)="combo.comboOpening(documentTypeLabel)" (selectionChanging)="combo.searchTypeChanging($event)"
      (closing)="combo.comboClosing(documentTypeLabel, documentTypeId)" [displayKey]="'Name'" [valueKey]="'Id'"
      [(ngModel)]="documentTypeId" [disabled]="!gotDocumentFullPermissions" [style.--ig-size]="'var(--ig-size-small)'">
    </igx-simple-combo>
  </div>
  }


  <div class=" div9">
    <span>Utgående:</span>
    <dlx-checkbox name="outgoing" id="outgoing" [value]="outgoing" [disabled]="true">
    </dlx-checkbox>
  </div>

  @if(fileExists) {
  <igx-input-group class=" div10" type="border" [style.--ig-size]="'var(--ig-size-small)'">
    <input igxInput name="greyDummy" type="text" [(ngModel)]="filename" required pattern="^\s*\S+.*$"
      [disabled]="!gotDocumentFullPermissions" />
    <label igxLabel for="greyDummy">Filnavn:</label>
  </igx-input-group>
  }

  <span class=" div11 ">{{ extension }}</span>

  <igx-input-group type="border" class="div12" [style.--ig-size]="'var(--ig-size-small)'">
    <textarea igxInput name="Description" autocomplete="off" type="text" rows="6" style="resize: none;"
      [(ngModel)]="description" [disabled]="!gotDocumentFullPermissions"></textarea>
    <label igxLabel for="Description">Beskrivelse:</label>
  </igx-input-group>

  @if(fileExists) {
  <button class="save-button div13" igxButton="contained" [style.color]="'white'" [style.background]="'#575757'"
    igxRipple="white" (click)="openFile()">Åpne</button>
  }

  @if(saving) {
  <div class="div14" id="loading"></div>
  } @else if(gotDocumentFullPermissions) {
  <button class="save-button div14" igxButton="contained" [style.color]="'white'"
    [style.background]=" enableSaveButton ? 'lightgrey' : '#AEC965'" igxRipple="white" (click)="onSave()"
    [disabled]="enableSaveButton">Lagre</button>
  }

  <button class="save-button div15" igxButton="contained" [style.color]="'white'" [style.background]="'#575757'"
    igxRipple="white" (click)="closeModal()">Avbryt</button>

</div>